<template>
  <div class="page-section">
    <form
      enctype="multipart/form-data"
      class="bg-white p-4 rounded-lg"
      method="post"
      action="/professionnel/ws/pre_inscription_hcp.php"
      @submit.prevent="event => !loading && submit(event)"
  >
    <fieldset :disabled="loading">
        <Input
          v-model="form.name"
          label="Nom"
          name="name"
          id="name"
          :custom-margin='true'
          required
        />
        <Input
          v-model="form.firstname"
          label="Prénom"
          name="firstname"
          id="firstname"
          :custom-margin='true'
          required
        />
        <Autocomplete
          v-model="form.speciality"
          :items="specialities.items"
          :formatItems="item => item.value"
          @search="fetchSpecialities"
          @loading="wilayas.loading = true"
          @focus="() => !specialities.items.length && fetchSpecialities()"
          :debounce="500"
          :bindValueOnSelect="item => item.value"
          :showValueOnSelect="item => item.value"
          :loadingState="specialities.loading"
          label="Spécialité"
          name="speciality"
          required
        />
        <Autocomplete
          v-model="form.wilaya"
          :items="wilayas.items"
          :formatItems="item => item.name"
          @search="fetchWilayas"
          @select="() => {
              towns.items = [];
              $refs.townsInput.giveFocus();
          }"
          @clear="() => $refs.townsInput.handleClear()"
          @loading="wilayas.loading = true"
          @focus="() => !wilayas.items.length && fetchWilayas()"
          :debounce="500"
          :bindValueOnSelect="item => item.id"
          :showValueOnSelect="item => item.name"
          :loadingState="wilayas.loading"
          :label="$t('_180')"
          name="wilaya"
          required
        />
        <Autocomplete
          v-model="form.town"
          :items="towns.items"
          :formatItems="item => item.name"
          @search="fetchTowns"
          @select="item => form.postalCode = item.code_postal"
          @clear="form.postalCode = ''"
          @loading="towns.loading = true"
          @focus="() => !towns.items.length && fetchTowns()"
          :debounce="500"
          :bindValueOnSelect="item => item.id"
          :showValueOnSelect="item => item.name"
          :loadingState="towns.loading"
          :disabled="!form.wilaya"
          ref="townsInput"
          label="Ville"
          name="town"
          required
      />
        <Input
          v-model="form.address"
          label="Adresse"
          name="address"
          id="address"
          :custom-margin='true'
          required
        />
        <Input
          v-model="form.postalCode"
          label="Code postal"
          name="postal"
          id="postal"
          :custom-margin='true'
          required
        />
        <Input
          v-model="form.phone"
          label="Téléphone"
          name="phone"
          id="phone"
          :custom-margin='true'
        />
       <Input
          v-model="form.email"
          label="Email"
          name="email"
          id="email"
          :custom-margin='true'
          required
        />
        <Checkbox
          v-model="form.consent_hta"
          name="consent_diab"
          id="consent_hta"
          :label="consent_hta"
          v-if="program == 'hta' || program == 'diab-hta'"
        />
        <Checkbox
          v-model="form.consent_diab"
          name="consent_diab"
          id="consent_diab"
          :label="consent_diab"
          v-if="program == 'diab' || program == 'diab-hta'"
        />
      <br>
      </fieldset>

      <fieldset :disabled="loading">
        <Submit
            class="text-center"
            label="Valider le parrainage"
        />
      </fieldset>
    </form>
  </div>
</template>

<script>
import Input from '@components/inputs/Input.vue';
import Checkbox from '@components/inputs/Checkbox.vue';
import Submit from '@components/inputs/Submit.vue';
import Autocomplete from '@components/inputs/Autocomplete.vue';
import { toFormData } from '@helpers';

export default {
  components: {
    Input,
    Submit,
    Checkbox,
    Autocomplete
  },
  name: "PreRegistrationHcp",
  props: {
    program: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      form: {
        email: '',
        name: '',
        firstname:'',
        speciality:'',
        phone:'',
        wilaya:'',
        address:'',
        town:'',
        postal:''
      },
      formSuccess: false,
      errors: null,
      wilayas: {
          items: [],
          loading: false,
      },
      towns: {
          items: [],
          loading: false,
      },
      specialities: {
          items: [],
          loading: false,
      },
    }
  },
  computed: {
    consent_hta: function(){
      let url = "/documents/cgu_professionnel_new.pdf";
      return `J'accepte les <a href='${url}' target="_blank" class='url_cgu'>Conditions Générales d'utilisations Programmes Support Patients</a> et confirme mon inscription au programme HTA eCare.`;
    },
    consent_diab: function(){
      let url = "/documents/cgu_professionnel_new.pdf";
      return `J'accepte les <a href='${url}'' target="_blank" class='url_cgu'>Conditions Générales d'utilisations Programmes Support Patients</a> et confirme mon inscription au programme Diab eCare.`;
    }
  },
  methods: {
    submit(event) {

    if ($('#consent_hta').length > 0 && !$('#consent_hta').prop('checked')) {
      window.showAlert('Erreur', 'Vous devez d’abord adhérer à un ou des programme(s) support patients pour accéder.');
      return;
    }

    if ($('#consent_diab').length > 0 && !$('#consent_diab').prop('checked')) {
      window.showAlert('Erreur', 'Vous devez d’abord adhérer à un ou des programme(s) support patients pour accéder.');
      return;
    }

    this.formSuccess = false;
    this.errors = null;
    this.loading = true;

    this.$axios.post(`/professionnel/ws/pre_inscription_hcp.php?programme=${this.program}`, toFormData(this.form))
        .then(response => {
            this.formSuccess = true;
            this.loading = false;
            showInfo('Information', 'Votre pré-inscription a bien été prise en compte.');
            window.location.href = "/professionnel";
        })
        .catch(e => {
          this.loading = false;
          const errors = e.response.data;
            for (const input in errors) {
              if (input === 'email') {
                window.showAlert(input, "Veuillez revoir le format de l'email."); 
              }
            }
          this.errors = errors;
        });
      },
      fetchWilayas(term) {
            this.wilayas.loading = true;

            const endpoint = '/professionnel/ws/get_liste_wilaya.php';

            this.$axios.get(`${endpoint}?term=${term || ''}`)
                .then(response => {
                    this.wilayas.items = response.data;
                    this.wilayas.loading = false;
                })
                .catch(e => {
                    this.wilayas.items = [];
                    this.wilayas.loading = false;
                });
        },

        fetchTowns(term) {
            this.towns.loading = true;

            const endpoint = '/professionnel/ws/get_liste_commune.php';
            const params = [
                '?wilaya_id=' + (this.form.wilaya || ''),
                '&term=' + (term || ''),
            ];

            this.$axios.get(endpoint + params.join(''))
                .then(response => {
                    this.towns.items = response.data;
                    this.towns.loading = false;
                })
                .catch(e => {
                    this.towns.items = [];
                    this.towns.loading = false;
                });
        },
        fetchSpecialities(term){
          this.specialities.loading = true;

          const endpoint = '/professionnel/ws/get_liste_specialty.php';
          const params = [
              '?term=' + (term || ''),
          ];

          this.$axios.get(endpoint + params.join(''))
              .then(response => {
                  this.specialities.items = response.data;
                  this.specialities.loading = false;
              })
              .catch(e => {
                  this.specialities.items = [];
                  this.specialities.loading = false;
              });
        }
    },
    mounted(){
      $('#consent_hta').next().html(this.consent_hta);
      $('#consent_diab').next().html(this.consent_diab);
    }
  }
</script>

<style scoped>
</style>
