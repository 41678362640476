import { render, staticRenderFns } from "./InsulinRecommandation.vue?vue&type=template&id=fc9e84b4&scoped=true"
import script from "./InsulinRecommandation.vue?vue&type=script&lang=js"
export * from "./InsulinRecommandation.vue?vue&type=script&lang=js"
import style0 from "./InsulinRecommandation.vue?vue&type=style&index=0&id=fc9e84b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc9e84b4",
  null
  
)

export default component.exports