<template>
  <div class="page-section h-100">
    <div class="container bop-container">
    <div class="header"><h2>{{ $t('_parcours_patient') }}</h2></div>
    <div class="row pt-0 mt-2">
      <div class="col-auto ml-auto">
        <div class="row justify-content-between align-items-center mt-5">
          <div class="col-auto">
            <p class="font-weight-bold mb-0" id="filterPeriodJourney">{{ $t('_62') }}</p>
          </div>
          <div class="col-12">
            <div class="form-group mb-0">
              <select class="form-control" id="js-filterPeriodJourney" name="filterPeriodJourney">
                <option value="period1">{{ $t('_63') }}</option>
                <option value="period2">{{ $t('_64') }}</option>
                <option value="period3" selected>{{  $t('_18_mois') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" id="consultationLegend">
        <p class="font-weight-bold mb-3">{{ $t('_65') }}</p>
        <div class="d-flex">
          <div class="mr-4">
            <div class="mb-2 legend-item">
              <div class="d-flex align-items-center">
                <div class="mark program-education mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_66') }}</p>
              </div>
            </div>
            <div class="mb-2 legend-item" v-if="user.has_diabete && isDiabCard">
              <div class="d-flex align-items-center">
                <div class="mark program-contact mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_67') }}</p>
              </div>
            </div>
            <div class="mb-2 legend-item">
              <div class="d-flex align-items-center">
                <div class="mark program-seances mb-0 mr-3"></div>
                <p class="mb-0" v-if="user.has_diabete && isDiabCard">{{ $t('_68') }}</p>
                <p class="mb-0" v-if="user.has_hta && isHtaCard">{{ $t('_229') }}</p>
              </div>
            </div>
            <div class="mb-2 legend-item" v-if="user.has_diabete && isDiabCard && isCommunicationEnabled(3)">
              <div class="d-flex align-items-center">
                <div class="mark program-sms mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_69') }}</p>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-2 legend-item" v-if="isCommunicationEnabled(4)">
              <div class="d-flex align-items-center">
                <div class="mark program-mail mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_70') }}</p>
              </div>
            </div>
            <div class="mb-2 legend-item">
              <div class="d-flex align-items-center">
                <div class="mark program-media mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_71') }}</p>
              </div>
            </div>
            <!--<div class="mb-2 legend-item">
              <div class="d-flex align-items-center">
                <div class="mark program-podcast mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_72') }}</p>
              </div>
            </div>-->
            <div class="mb-2 legend-item" v-if="user.has_diabete && isDiabCard">
              <div class="d-flex align-items-center">
                <div class="mark program-chatbot mb-0 mr-3"></div>
                <p class="mb-0">{{ $t('_73') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="figure-container mt-5 mb-5 mx-4" id="consultationGraph">
          <div id="js-dots-events" class="figure-content d-flex justify-content-between align-items-end pb-3 pt-4 pr-3 pl-3 mr-3 ml-3">
          </div>
          <hr class="divider mt-0">
          <div id="js-patient-timeline" class="figure-timeline d-flex justify-content-between align-items-end text-center mt-4 px-4 mx-2">
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  name: "JourneyEducationalPrograms",
  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },
    communication_list:{
      type : [],
      required : true,
    }
  },
  data() {
    return {
      isHtaCard : false,
      isDiabCard : false,
    }
  },
  mounted(){
    this.isHtaCard = sessionStorage.getItem('fds') === 'hta';
    this.isDiabCard = sessionStorage.getItem('fds') === 'diabete';
  },
  methods:{
    isCommunicationEnabled(id) {
      const conditionObj = this.communication_list.find(cond => cond.id === id);
      return conditionObj && conditionObj.enabled ;
    },
  }
}
</script>

<style scoped>

</style>
