<template>
    <div class="border rounded row">
        <div 
        class="container-image rounded-left col-sm-12 col-md-2 col-lg-2" 
        :style="{ backgroundImage: `url(${require('../../../../../../public/img/carnet/' + getImage)})` }" 
        >
        </div>
        <div class="col-sm-12 col-md-10 col-lg-10 pt-3">
            <h2 class="mb-0 ml-3">{{ $t('_'+ getLabel) }}</h2>
            <component :is="getLabel" :period="period" @updateData="$emit('updateData', $event)"/>
        </div>
    </div>
</template>

<script>
import Breakfast from '@components/logbook/patient/periods/Breakfast.vue'
import Lunch from '@components/logbook/patient/periods/Lunch.vue'
import Snack from '@components/logbook/patient/periods/Snack.vue'
import Supper from '@components/logbook/patient/periods/Supper.vue'
import Night from '@components/logbook/patient/periods/Night.vue'

export default {
name: "LogbookPatient", 
components: {
    Breakfast, 
    Lunch, 
    Snack, 
    Supper, 
    Night
},
props: {
    period: {
      type: Object
    }
  },
computed: {
  getLabel() {
    let label; 
      
      switch (this.period.period_type_id) {
          case 1: label = 'breakfast'
            break;
          case 2: label = 'lunch'
            break;
          case 3: label = 'snack'
            break;
          case 4: label = 'supper'
            break;
          case 5: label = 'night'
            break;
          default: label = ""
            break;
        }
      return label
  },
  getImage() {
    let image; 
      
      switch (this.period.period_type_id) {
          case 1: image = "matin.png"
            break;
          case 2: image = "midi.png"
            break;
          case 3: image = "apres-midi.png"
            break;
          case 4: image = "soir.png"
            break;
          case 5: image = "au-coucher.png"
            break;
          default: image = ""
            break;
        }
      return image
    }
  }, 
}
</script>

<style scoped>
.container-image {
  color: transparent;
  background-size: cover;
  background-position: center;
  
}

@media only screen and (max-width: 768px) {
.container-image {
  height: 17rem;
}
}

</style>