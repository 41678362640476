var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section h-100" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("_55")))]),
    _vm._v(" "),
    _c("div", [
      _c("p", { staticClass: "font-weight-bold mt-4 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("_56"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "nbSmsReceived" }, [
        _vm._v(_vm._s(_vm.sms.length)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "table",
            {
              staticClass: "message_history table table--user table-striped",
              attrs: { id: "sms_history" },
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_57"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_58"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_59"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.pagesSms, function (sms) {
                  return _vm.loading
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(sms.title))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$luxon
                                .fromISO(sms.date_updated)
                                .setLocale("fr")
                                .toLocaleString(_vm.$luxon.DATE_SHORT)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.$t("Sent")))]),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _vm.sms.length > 0
            ? _c("Pagination", {
                attrs: { contents: _vm.sms, "per-page": _vm.perPage },
                on: { pages: _vm.pages },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }