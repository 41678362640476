var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("_46")))]),
    _vm._v(" "),
    _c("div", [
      _c("p", { staticClass: "font-weight-bold mt-4 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("_47"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "nbSmsReceived" }, [
        _vm._v(_vm._s(_vm.datas.length)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "table",
          {
            staticClass: "table table--user table-striped",
            attrs: { id: "ftfsession_history" },
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("_48"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("_49"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.datas, function (data, index) {
                return _c("tr", [
                  _c("td", [_vm._v(_vm._s(data.name))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$luxon
                          .fromISO(data.date)
                          .setLocale("fr")
                          .toLocaleString(_vm.$luxon.DATE_SHORT)
                      )
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }