<template>
    <div class="page-section h-100">
        <div class="change-mode" id="modifierFicheMedical" v-on:click="redirectFormMedical()">
            <div id="iconModif">
                <i class="fas fa-pencil-alt"></i>
                <span class="change-tooltip">{{ $t("_74") }}</span>
            </div>
        </div>
        <h2 >{{ $t("_75") }}</h2>
      <div class="h-100">
        <div class="row mt-4" v-if="user.diabetes.diabetes_active && !isHtaCard">
          <div class="col-md-6">
            <h3 class="title_fiche_patient">{{ $t("_76") }}</h3>
              <span>{{ translateDiabetesType }}</span>
          </div>
          <div class="col-md-6" v-if="user.diabetes.diabetes_active && !isHtaCard">
            <h3 class="title_fiche_patient">{{ $t("_154") }}</h3>
            <span>{{ diabetesTreatmentJoined }}</span>
          </div>
        </div>
        <div class="row mt-4" v-if="user.hypertension.hypertension_active && !isDiabCard">
          <div class="col-md-6">
            <h3 class="title_fiche_patient">{{ $t("_76") }}</h3>
              <span>{{ $t("_149") }}</span>
          </div>
          <div class="col-md-6">
            <h3 class="title_fiche_patient">{{ $t("_hypertension_treatments") + ' :' }}</h3>
            <span>{{ hypertensionTreatmentFromList }}</span>
          </div>
        </div>
        <div class="row mt-4" v-if="user.diabetes.diabetes_active && !isHtaCard">
          <div class="col-md-12">
            <h3 class="title_fiche_patient">{{ $t("_77") }}</h3>
            <span v-if="user.diabetes.insulin_less_than_3_months == 1 && !isHtaCard">{{ $t("_78") }}</span>
            <span v-else >{{ $t("_79") }}</span>
          </div>
        </div>
        <div class="row mt-4" v-if="user.hypertension.hypertension_active && !isDiabCard">
          <div class="col-md-6">
            <h3 class="title_fiche_patient">{{ $t("_antihypertensive_less_than_6_months") }}</h3>
            <span>{{ HasHTaHypertensive }}</span>
          </div>
          <div class="col-md-6">
            <h3 class="title_fiche_patient">{{ $t("_tabagisme") }}</h3>
            <span>{{ HasHTaSmoking }}</span>
          </div>
          <div class="col-md-6 mt-4">
            <h3 class="title_fiche_patient">{{ $t("_p1") }}</h3>
            <span>
              Diab eCare / Diab eSupport : {{ hasDiabECare }}
            </span> <br>
            <span>
              HTA eCare : {{ hasHtaECare }}
            </span>
          </div>
        </div>
        <div v-if="context != 'cro' && isLogbookActive && !isHtaCard" class="row mt-4">
          <div class="col-md-12">
            <h3 class="title_fiche_patient">{{ $t("_80") }}</h3>
          </div>
        </div>
        <div v-if="context != 'cro' && isLogbookActive && !isHtaCard" class="row mt-2">
          <div class="col-sm-12 col-md-6 col-md-6">
              <button class="btn btn-outline-primary" @click="goToLogbook()">
                <icon-book class="mr-2"></icon-book>
                {{ isCreated ? $t("_edit_logbook") : $t("_create_logbook")}}
              </button>
          </div>
          <div class="col-sm-12 col-md-6 col-md-6" v-if="isCreated == true">
            <button class="btn btn-outline-primary" @click="goToFollowUp()"><icon-grow class="mr-2"></icon-grow>{{ $t("_check_stats") }}</button></div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12">
            <button class="btn btn-outline-primary" @click="goToConsentFile()">
              {{ $t("_101") }}
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import InputGroup from "@components/inputs/InputGroup.vue";
import Input from "@components/inputs/Input.vue";
import Radio from "@components/inputs/Radio.vue";
import Autocomplete from "@components/inputs/Autocomplete.vue";
import Checkbox from "@components/inputs/Checkbox.vue";
import FileUpload from "@components/inputs/FileUpload.vue";
import Submit from "@components/inputs/Submit.vue";
import apiCall from '../../apiLogbook';

export default {
  components: {
    InputGroup,
    Input,
    Radio,
    Autocomplete,
    Checkbox,
    FileUpload,
    Submit
  },

  name: "MedicalProfil",
  data() {
    return {
      isCreated: false,
      isLogbookActive: false,
      isHtaCard : false,
      isDiabCard : false,
    }
  },
  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },


    uuid: {
      type: String,
      required: true,
    },
    patient_id: {
      type: String,
      required: true
    }
  },

  computed: {
    diabetesTreatmentJoined: function() {
      if(this.user.diabetes.diabetes_active == true) {
        var data_treatments = [];
        Object.keys(this.user.diabetes.treatments).forEach(key => {
            data_treatments.push(key)
        });
        return data_treatments.join(", ");
      }

      return ''
    },

    hasHtaECare: function() {
      return this.user.programs.some(function(element) {
        return element.id == 3;
      }) ? $t('_yes') : $t('_no');
    },

    hasDiabECare: function() {
      return this.user.programs.some(function(element) {
        return element.id == 1;
      }) ? $t('_yes') : $t('_no');
    },

    HasHTaHypertensive: function() {
      return this.user.hypertension.antihypertensive_less_than_6_months ? $t('_yes') : $t('_no');
    },

    HasHTaSmoking: function() {
      return this.user.hypertension.smoking ? $t('_yes') : $t('_no');
    },

    hypertensionTreatmentFromList: function() {
      if(this.user.hypertension.hypertension_active == true) {
        var data_treatments = [];
        Object.keys(this.user.hypertension.treatments).forEach(key => {
            data_treatments.push(key)
        });
        return data_treatments.join(", ");
      }

      return ''
    },

    translateDiabetesType: function() {
      switch (this.user.diabetes.diabete_type) {
        case 1:
          return this.$t('_151')
        case 2:
          return this.$t('_152')
        case 3:
          return this.$t('_153')
        default:
          return ''
      }
    }
  },
  created() {
    let searchTerm = "Digital Logbook";
    this.isLogbookActive = this.user.solution.includes(searchTerm);
    this.getLogbook();
  },
  methods: {
    redirectFormMedical() {
      window.location.href = 'patient_medical_form.php'
    },
    createLogbook() {
      apiCall.create('api/glycemic_logbooks', {patient_id: this.patient_id})
            .then(() => {
              window.location.href = '/professionnel/digital-logbook'
            })
            .catch((error) => {});
    },
    getLogbook() {
      apiCall.get('api/glycemic_logbooks', this.patient_id)
          .then((response) => {
            if(response.data.glycemic_episodes.length != 0 || response.data.glycemic_logbook_parameters !== null || response.data.glycemic_objectives.length != 0 || response.data.periods.length != 0){
              this.isCreated = true;
            }
          })
          .catch((error) => {
            this.isCreated = false;
          });
    },
    goToLogbook() {
      this.isCreated ? window.location.href = '/professionnel/digital-logbook' : this.createLogbook();
    },
    goToFollowUp(){
      window.location.href = '/professionnel/follow-up';
    },
    goToConsentFile() {
      window.open(`/patient/consentement.php?patient_id=${this.patient_id}`, '_blank)');
    }
  },
  mounted(){
    this.isHtaCard = sessionStorage.getItem('fds') === 'hta';
    this.isDiabCard = sessionStorage.getItem('fds') === 'diabete';
  }
}
</script>

<style scoped>
  .title_fiche_patient {
    color: rgb(128,128,128);
  }
</style>
