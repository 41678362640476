<template>
  <div class="page-section h-100">
    <h2>{{ $t('_55') }}</h2>
    <div>
      <p class="font-weight-bold mt-4 mb-1">{{ $t('_56') }}</p>
      <p class="nbSmsReceived">{{ sms.length }}</p>
    </div>
    <div class="row mb-4">
      <div class="col">
        <table id="sms_history" class="message_history table table--user table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('_57') }}</th>
            <th scope="col">{{ $t('_58') }}</th>
            <th scope="col">{{ $t('_59') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading" v-for="sms in pagesSms">
            <td>{{ sms.title }}</td>
            <td>{{ $luxon.fromISO(sms.date_updated).setLocale("fr").toLocaleString($luxon.DATE_SHORT) }}</td>
            <td>{{ $t('Sent') }}</td>
          </tr>
          </tbody>
        </table>
        <Pagination v-if="sms.length > 0" @pages="pages" :contents="sms" :per-page="perPage"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@components/inputs/Pagination.vue";

export default {
  components: {
    Pagination
  },

  name: "HistorySms",

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      loading: false,
      perPage: 5,
      sms: [],
      pagesSms: [],
    }
  },

  mounted() {
    const endpoint = '/ws/get_history_sms.php';
    const params = [
      '?patient_id=' + this.user.patient_id
    ];

    this.$axios.get(endpoint + params)
      .then(response => {
        this.sms = response.data.data;
      })
      .catch(e => {
        console.error(e);
      });
  },

  methods: {
    pages(value) {
      this.pagesSms = value;
      this.loading = true;
    },
  }
}
</script>
