<template>
    <InputGroup
        bold
        :label="$t('_193')"
        :required="!!selectedPrograms"
      >
        <p v-show="!selectedPrograms">
          {{ $t('_220') }}
        </p>

        <Checkbox
          v-show="!!selectedPrograms"
          v-model="form.consent"
          name="consentPrograms"
          id="consentPrograms"
          :label="selectedPrograms"
        />
    </InputGroup>
</template>

<script>
import InputGroup from '@components/inputs/InputGroup.vue';
import Checkbox from '@components/inputs/Checkbox.vue';

export default {
    components: {
        InputGroup,
        Checkbox,
    },

    props: {
        form: {
            type: Object,
            required: true,
        },
        programs: {
            type: Array,
            required: true
        },

    },

    data() {
      return {
        selectedPrograms: ''
      }
    },

    watch: {
      'form.medical.diabetes.programs': function (val, oldVal) { this.mergeSelectedPrograms() },
      'form.medical.hypertension.programs': function (val, oldVal) { this.mergeSelectedPrograms() },
    },

    methods: {
      mergeSelectedPrograms() {
        let array =  this.form.medical.diabetes.programs.concat(this.form.medical.hypertension.programs)
        let selected =  []
        this.programs.forEach((element) => {
          if (array.includes(parseInt(element.id))) {
            selected.push(element.name_fr_FR)
          }
        })
        if (selected.length > 0) {
          this.selectedPrograms = selected.length === 1
          ? this.$i18n.t('_213', {program: `${selected[0]}`})
          : this.$i18n.t('_214', {programs: `${selected.join(', ')}`})
        } else {
          this.selectedPrograms = ''
        }
      }
    }
}
</script>
