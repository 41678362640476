<template>
  <div>
    <form
        enctype="multipart/form-data"
        class="bg-white p-4 rounded-lg d-none"
        method="post"
        :action="prefix + '/ws/insert_patient.php'"
        @submit.prevent="event => !loading && submit(event)"
        id="formPatient"
    >
        <fieldset :disabled="loading">
            <legend>
                <h2 class="pt-0 mb-0">{{ $t("_96") }}</h2>
            </legend>

            <Input
                v-model="form.name"
                :label="$t('_167')"
                name="name"
                required
            />

            <Input
                v-model="form.firstname"
                :label="$t('_168')"
                name="firstname"
                required
            />

            <InputGroup :label="$t('_169')" required>
                <Radio
                    v-model="form.gender"
                    value="1"
                    name="gender"
                    id="gender1"
                    :label="$t('_170')"
                    required
                />

                <Radio
                    v-model="form.gender"
                    value="2"
                    name="gender"
                    id="gender2"
                    :label="$t('_171')"
                />
            </InputGroup>

            <Input
                v-model="form.birthdate"
                type="date"
                :label="$t('_172')"
                name="birthdate"
                min="1900-01-01"
                :max="$luxon.now().toFormat('YYYY-MM-DD')"
                :custom-margin='true'
                required
            />

            <InputGroup
                :label="$t('_173')"
                required
            >
                <Radio
                    v-model="form.lang"
                    value="fr"
                    name="lang"
                    id="lang1"
                    :label="$t('_174')"
                    required
                />

                <Radio
                    v-model="form.lang"
                    value="ar"
                    name="lang"
                    id="lang2"
                    :label="$t('_175')"
                />

                <!--TODO disabled for now
                <Checkbox
                    v-model="form.langExtra"
                    name="langExtra"
                    id="lang-extra"
                    :label="$t('_176')"
                />-->
                <Checkbox
                    v-model="form.langExtra"
                    name="langExtra"
                    id="lang-extra"
                    :label="$t('_176')"
                    :disabled="true"
                    :readonly="true"
                />
            </InputGroup>

            <InputGroup
                :label="helperLabels.helper"
                required
            >
                <Radio
                    v-model="form.helper"
                    value="1"
                    name="helper"
                    id="helper1"
                    :label="$t('_177')"
                    :checked="isPatientMinor"
                    :disabled="isPatientMinor"
                    required
                />

                <Radio
                    v-model="form.helper"
                    value="0"
                    name="helper"
                    id="helper2"
                    :label="$t('_178')"
                    :disabled="isPatientMinor"
                />
            </InputGroup>
        </fieldset>

        <fieldset :disabled="loading">
            <legend>
                <h2 class="mb-0">{{ $t("_97") }}</h2>
            </legend>

            <Input
                v-model="form.cellphone"
                :label="helperLabels.cellphone"
                name="cellphone"
                :tooltip="$t('cellphone_tooltip')"
                required
            />

            <Input
                v-model="form.telephone"
                :label="helperLabels.telephone"
                name="telephone"
            />

            <Input
                v-model="form.email"
                type="email"
                :label="helperLabels.email"
                name="email"
                :tooltip="$t('email_tooltip')"
                :custom-margin='true'
            />

            <Input
                v-model="form.emailConfirmation"
                type="email"
                :label="$t('_179')"
                name="emailConfirmation"
                :custom-margin='true'
            />

            <Autocomplete
                v-model="form.wilaya"
                :items="wilayas.items"
                :formatItems="item => item.name"
                :initialValue="items => items.find(item => item.id === form.wilaya).name"
                @search="fetchWilayas"
                @select="() => {
                    towns.items = [];
                    $refs.townsInput.giveFocus();
                }"
                @clear="() => $refs.townsInput.handleClear()"
                @loading="wilayas.loading = true"
                @focus="() => !wilayas.items.length && fetchWilayas()"
                :debounce="500"
                :bindValueOnSelect="item => item.id"
                :showValueOnSelect="item => item.name"
                :loadingState="wilayas.loading"
                :label="$t('_180')"
                name="wilaya"
                required
            />

            <Autocomplete
                v-model="form.town"
                :items="towns.items"
                :formatItems="item => item.name"
                :initialValue="items => items.find(item => item.id === form.town).name"
                @search="fetchTowns"
                @select="item => form.postalCode = item.code_postal"
                @clear="form.postalCode = ''"
                @loading="towns.loading = true"
                @focus="() => !towns.items.length && fetchTowns()"
                :debounce="500"
                :bindValueOnSelect="item => item.id"
                :showValueOnSelect="item => item.name"
                :loadingState="towns.loading"
                :disabled="!form.wilaya"
                ref="townsInput"
                :label="$t('_181')"
                name="town"
                required
            />

            <Input
                v-model="form.postalCode"
                :label="$t('_182')"
                name="postalCode"
                readonly
                :custom-margin='true'
            />

            <Input
                v-model="form.address"
                :label="$t('_183')"
                name="address"
            />
        </fieldset>

        <PatientMedicalFormGroup
            :context="context"
            :form="form.medical"
            :treatments="treatments"
            :programs="programs"
            :rulesData="{
                cellphone: form.cellphone,
                email: form.email,
            }"
            :loading="loading"
            :hcp="hcp"
            :canView="canView"
            :notMatch="notMatch"
            :isPatientMinor="isPatientMinor"
        />

        <fieldset :disabled="loading">
            <legend>
                <h2 class="mb-0">{{ $t("_101") }}</h2>
            </legend>

            <InputGroup :label="$t('_190')" bold>
                <PatientTOS class="mt-2 mb-3" />
            </InputGroup>

            <hr />

            <InputGroup :label="$t('_191')" bold>
                <p class="mb-0">
                    1.
                    <a
                        @click="getPdf()"
                        class="txt-violet"
                    >
                      {{ $t('_217')}}.
                    </a>
                </p>

                <FileUpload
                    v-model="form.consentForm"
                    :label="$t('_192')"
                    name="consentForm"
                    accept="image/*, application/pdf"
                    :required="context === 'hcp' ? false : true"
                />
            </InputGroup>

            <hr />

            <ConsentPrograms
                :form="this.form"
                :programs="this.programs"
            />
        </fieldset>

        <fieldset :disabled="loading">
            <Submit
                class="text-center"
                :label="$t('_194')"
            />
        </fieldset>

        <PatientSignUpSuccess
            :isOpen="formSuccess"
            @close="redirectAfterConnect"
            :context="context"
            :solutions="form.medical.solutions"
        />
    </form>

    <div class="card d-none" style="max-width: 100% !important;" id="cardNotInsert">
      <div class="card-body">
        <h2 style="padding: 0 !important;"></h2>
        <p>
          Ce patient est déjà inscrit aux programmes de support patient Diab eCare et HTA eCare. Vous ne pouvez pas effectuer de nouvelle inscription à un PSP.
        </p>
      </div>
    </div>

    <div class="card d-none" style="max-width: 100% !important;" id="cardNotInsertCro">
      <div class="card-body">
        <h2 style="padding: 0 !important;"></h2>
        <p>
          Le patient est déjà inscrit au programme de support patient Diab eCare. Vous n'avez pas les droits pour inscrire le patient au programme support patient HTA eCare.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PatientMedicalFormGroup from '@components/forms/PatientMedicalFormGroup.vue';
import InputGroup from '@components/inputs/InputGroup.vue';
import Input from '@components/inputs/Input.vue';
import Radio from '@components/inputs/Radio.vue';
import Autocomplete from '@components/inputs/Autocomplete.vue';
import Checkbox from '@components/inputs/Checkbox.vue';
import FileUpload from '@components/inputs/FileUpload.vue';
import Submit from '@components/inputs/Submit.vue';
import PatientTOS from '@components/PatientTOS.vue';
import PatientSignUpSuccess from '@components/modals/PatientSignUpSuccess.vue';
import ConsentPrograms from '@components/forms/ConsentPrograms.vue';
import { toFormData } from '@helpers';

export default {
    components: {
        PatientMedicalFormGroup,
        InputGroup,
        Input,
        Radio,
        Autocomplete,
        Checkbox,
        FileUpload,
        Submit,
        PatientTOS,
        PatientSignUpSuccess,
        ConsentPrograms,
    },

    props: {
        context: {
            type: String,
            required: true,
        },

        hcp: {
            type: Object,
            required: true,
        },

        treatments: {
            type: Object,
            required: true,
        },

        programs: {
            type: Array,
            required: true,
        },

        solutions: {
            type: Object,
            required: true,
        },
        data_wilayas: {
          type: Array,
          required: true,
          loading: false,
        },

        data_towns: {
          type: Array,
          required: true,
          loading: false,
        },
    },

    data() {
        return {
            loading: false,
            canInsert: false,
            canView: {
              diabeteData: '',
              htaData: '',
            },
            notMatch: {
              diabete: '',
              hta: '',
            },

            form: {
                createdBy: this.hcp.uuid,
                patient_id: null,
                name: '',
                firstname: '',
                gender: '',
                birthdate: '',
                cellphone: '',
                telephone: '',
                email: '',
                emailConfirmation: '',
                wilaya: '',
                town: '',
                postalCode: '',
                address: '',
                lang: '',
                langExtra: false,
                helper: '',
                consentForm: null,
                consent: null,

                medical: {
                    diabetes: {
                        code: this.hcp.code,
                        active: false,
                        treatments: [],
                        programs: [],
                        type: '',
                        insulinLessThan3Months: '',
                    },
                    hypertension: {
                        code: this.hcp.code,
                        active: false,
                        treatments: [],
                        programs: [],
                        data: {
                          antihypertensive_less_than_6_months: null,
                          smoking: '',
                          illness : ''
                        },
                    },
                    solutions: ['5']
                },
            },

            wilayas: {
                items: this.data_wilayas,
                loading: false,
            },

            towns: {
                items: this.data_towns,
                loading: false,
            },

            formSuccess: false,

            patientData: null,
            errors: null,
        };
    },

    computed: {
        prefix() {
            return this.context === 'hcp' ? '/professionnel' : '/cro';
        },

        allPrograms() {
            let diabetesPrograms = this.form.medical.diabetes.programs.map((program) => {
              return Object.keys(this.programs)[Object.values(this.programs).indexOf(program)];
            });

            let hypertensionPrograms = this.form.medical.hypertension.programs.map((program) => {
              return Object.keys(this.programs)[Object.values(this.programs).indexOf(program)];
            });

            return [
                ...diabetesPrograms,
                ...hypertensionPrograms,
            ];
        },

        isPatientMinor() {
            if (!this.form.birthdate.length) {
                return false;
            }

            const birthdate = this.$luxon.fromISO(this.form.birthdate);
            const now = this.$luxon.now();
            
            return now.diff(birthdate, 'years').years < 18;
        },
        helperLabels() {
          const isTutor = this.isPatientMinor ? 1 : 0;
          const helper = this.$i18n.t(isTutor ? '_211' : '_212');

          const cellphone = this.form.helper === '1' ?
            this.$i18n.t(isTutor ? '_200' : '_201') :
            this.$i18n.t('_199');
          const telephone = this.form.helper === '1' ?
            this.$i18n.t(isTutor ? '_203' : '_204') :
            this.$i18n.t('_202');
          const email = this.form.helper === '1' ?
            this.$i18n.t(isTutor ? '_206' : '_207') :
            this.$i18n.t('_205');

        return { helper, cellphone, telephone, email};
        }
    },
    methods: {
        getPatientData(){
            const isInDb = sessionStorage.getItem('isInDb');
            if(isInDb === "true"){
            const endpoint = this.prefix + '/ws/get_infos_patient.php';
            const patient_id = sessionStorage.getItem('patient_uuid');
            const hcp_id = this.hcp.uuid;
            this.form.patient_id = sessionStorage.getItem('patient_uuid');

            this.$axios.get(`${endpoint}?uuid=${hcp_id}&patient_id=${patient_id}`)
                .then(response => {
                  let data = response.data.data;
                    this.form.name = data.name;
                    this.form.firstname = data.forname;
                    this.form.birthdate = data.birthdate;
                    this.form.cellphone = data.phone.split(' ').join('');
                    this.form.telephone = data.phone2.split(' ').join('');
                    this.form.email = data.email;
                    this.form.emailConfirmation = data.email;
                    this.form.gender = (data.gender).toString();
                    this.form.lang = 'fr';
                    this.form.helper = (data.helper).toString();
                    this.form.address = data.address;
                    this.form.wilaya = data.wilaya_id;
                    this.form.town = data.commune_id;
                    this.form.postalCode = this.data_towns.find(item => item.id == data.commune_id).code_postal
                  if (data.has_diabete) {
                    this.form.medical.diabetes.type = data.diabetes.diabete_type;
                    this.form.medical.diabetes.insulinLessThan3Months = data.diabetes.insulin_less_than_3_months.toString();
                    this.form.medical.diabetes.treatments = Object.values(data.diabetes.treatments);
                    this.form.medical.diabetes.solutions = data.diabetes.solutions ? Object.values(data.diabetes.solutions) : [];
                  }
                })
                .catch(e => {
                    console.error(e);
                });
          }

          if (isInDb === "false") {
            sessionStorage.getItem('search_nom') ? this.form.name = sessionStorage.getItem('search_nom') : '';
            sessionStorage.getItem('search_prenom') ? this.form.firstname = sessionStorage.getItem('search_prenom') : '';
            sessionStorage.getItem('search_birthdate') ? this.form.birthdate = sessionStorage.getItem('search_birthdate') : '';
            sessionStorage.getItem('search_tel') ? this.form.cellphone = sessionStorage.getItem('search_tel') : '';
            sessionStorage.getItem('search_mail') ? this.form.email = sessionStorage.getItem('search_mail') : '';
            sessionStorage.getItem('search_mail') ? this.form.emailConfirmation = sessionStorage.getItem('search_mail') : '';
          }
        },
        async checkIfHcpCanInsert() {
            const endpoint = this.prefix + '/ws/check_if_hcp_can_insert.php';
            const patient_id = sessionStorage.getItem('patient_uuid');
            
            try {
                const response = await this.$axios.get(`${endpoint}?uuid=${patient_id}`);
                this.canInsert = response.data.data;

                if (this.context === 'cro') {
                    const croCanInsertDiabete = (element) => element.type_pds === 'diabete';
                    const croCanInsertHta = (element) => element.type_pds === 'hta';
                    if (this.canInsert.some(croCanInsertDiabete)) {
                        $('#cardNotInsertCro').removeClass('d-none').addClass('d-block');
                    } else if (this.canInsert.some(croCanInsertHta)) {
                        this.canView.htaData = true;
                        $('#formPatient').removeClass('d-none').addClass('d-block');
                        $('#conditionHypertension').prop('checked', true);
                    } else{
                        this.canView.htaData = false;
                        $('#formPatient').removeClass('d-none').addClass('d-block');
                    }
                } else {
                    if (this.canInsert.length <= 1) {
                        $('#formPatient').removeClass('d-none').addClass('d-block');

                        if(this.canInsert.length == 1) {
                            const type_pds = this.canInsert[0].type_pds;
                            const isMatchingHcp = this.canInsert[0].hcp_uuid === this.hcp.uuid;

                            this.canView.diabeteData = isMatchingHcp && type_pds === 'diabete';
                            if (this.canView.diabeteData) {
                                $('#conditionDiabetes').prop('checked', this.canView.diabeteData);
                            }

                            if (!this.canView.diabeteData && type_pds === 'diabete') {
                            this.notMatch.diabete = true;
                                $('#conditionDiabetes').prop('checked', true);
                            }

                            this.canView.htaData = isMatchingHcp && type_pds === 'hta';
                            if (this.canView.htaData) {
                                $('#conditionHypertension').prop('checked', this.canView.htaData);
                            }

                            if (!this.canView.htaData && type_pds === 'hta') {
                            this.notMatch.hta = true;
                                $('#conditionHypertension').prop('checked', true);
                            }
                        }
                    } else {
                        $('#cardNotInsert').removeClass('d-none').addClass('d-block');
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        fetchWilayas(term) {
            this.wilayas.loading = true;

            const endpoint = this.prefix + '/ws/get_liste_wilaya.php';

            this.$axios.get(`${endpoint}?term=${term || ''}`)
                .then(response => {
                    this.wilayas.items = response.data;
                    this.wilayas.loading = false;
                })
                .catch(e => {
                    console.error(e);
                    this.wilayas.items = [];
                    this.wilayas.loading = false;
                });
        },

        fetchTowns(term) {
            this.towns.loading = true;

            const endpoint = this.prefix + '/ws/get_liste_commune.php';
            const params = [
                '?wilaya_id=' + (this.form.wilaya || ''),
                '&term=' + (term || ''),
            ];

            this.$axios.get(endpoint + params.join(''))
                .then(response => {
                    this.towns.items = response.data;
                    this.towns.loading = false;
                })
                .catch(e => {
                    console.error(e);
                    this.towns.items = [];
                    this.towns.loading = false;
                });
        },
        getPdf() {
            this.$axios.post(this.prefix + '/consent_cgu_patient.php', toFormData(this.form), { responseType: 'arraybuffer'})
                .then((response) => {
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Consentement.pdf';
                    link.click();
                });
        },
        submit(event) {
            this.formSuccess = false;
            this.errors = null;
            this.loading = true;

            this.$axios.post(this.prefix + '/ws/insert_patient.php', toFormData(this.form))
                .then(response => {
                    this.formSuccess = true;
                    this.patientData = response.data.data;
                    sessionStorage.setItem('patient_id', this.patientData.patient_id);
                    this.loading = false;
                    if (!this.isInDb) {
                      sessionStorage.getItem('search_nom') ? sessionStorage.removeItem('search_nom') : '';
                      sessionStorage.getItem('search_prenom') ? sessionStorage.removeItem('search_prenom') : '';
                      sessionStorage.getItem('search_birthdate') ? sessionStorage.removeItem('search_birthdate') : '';
                      sessionStorage.getItem('search_tel') ? sessionStorage.removeItem('search_tel') : '';
                      sessionStorage.getItem('search_mail') ? sessionStorage.removeItem('search_mail') : '';
                    }
                })
                .catch(e => {
                    this.loading = false;
                    const errors = e.response.data;
                    if (typeof errors === 'object' && Object.prototype.toString.call(errors) === '[object Object]') {
                      for (const input in errors) {
                        if(input == 'consent') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Vous devez confirmer avoir le consentement du patient.`
                            );
                        } else if(input == 'medical.diabetes.treatments') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Le champ « traitement » est obligatoire`
                            );
                        } else if(input == 'emailConfirmation') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Veuillez confirmer l'adresse mail`
                            );
                        }
                        else if(input == 'medical.hypertension.active') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Profil médical hypertension : Les champs notés * sont obligatoires`
                            );
                        }
                        else if(input == 'medical.hypertension.treatments') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Les champs notés * sont obligatoires : Le champ "traitement" est obligatoire`
                            );
                        }
                        else if(input == 'medical.hypertension.data.smoking') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Les champs notés * sont obligatoires : Le champ "tabagisme" est obligatoire`
                            );
                        }
                        else if(input == 'medical.hypertension.data.antihypertensive_less_than_6_months') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Les champs notés * sont obligatoires : Le champ "traité par un anti-hypertenseur depuis moins de 6 mois" est obligatoire`
                            );
                        }
                        else if(input == 'medical.diabetes.type') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Les champs notés * sont obligatoires : Le champ "type de diabète" est obligatoire`
                            );
                        }
                        else if(input == 'medical.diabetes.insulinLessThan3Months') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Les champs notés * sont obligatoires : Le champ "traité à l'insuline depuis moins de 3 mois" est obligatoire`
                            );
                        }
                        else if(input == 'medical.diabetes.active') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Profil médical diabète : Les champs notés * sont obligatoire`
                            );
                        }

                        else if(input == 'email') {
                            window.showAlert(
                            input,
                            // TODO: implement error messages with translation
                            // library
                            `Le format de l'adresse mail n'est pas respecté`
                            );
                        } else {
                            window.showAlert(
                                input,
                                // TODO: implement error messages with translation
                                // library
                                `${input}: ${errors[input].join(', ')}`
                            );
                        }
                      }
                    } else if(typeof errors === 'string') {
                      window.showAlert(
                        "Erreur",
                        errors
                      );
                    }
                    this.errors = errors;
                });
        },

        redirect() {
            window.location = this.prefix + '/home.php';
        },
        redirectAfterConnect(){
          if(this.form.medical.diabetes.active === true && this.form.medical.hypertension.active === true) {
            window.location = this.prefix + '/fiche_patient_before.php';
          } else if (this.form.medical.diabetes.active === true) {
            sessionStorage.setItem('fds', 'diabete');
            window.location = this.prefix + '/fiche_patient.php';
          } else if (this.form.medical.hypertension.active === true) {
            sessionStorage.setItem('fds', 'hta');
            window.location = this.prefix + '/fiche_patient.php';
          }
        },
    },
    mounted(){
      this.fetchWilayas();
      this.getPatientData();
      this.checkIfHcpCanInsert();
    }
};
</script>

<style>
.bg-white {
    color: inherit !important;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

.input {
    padding: 0;
}

.input .input-label {
    color: #23004C !important;
    font-size: 18px !important;
}

.label-multisolutions {
    padding-left: .5em;
    line-height: 2em;
    font-weight: bold;
    border-bottom: 1px solid;
}

.solution-education {
    color: #b9b850;
}

.solution-monitoring {
    color: #cb9d5d;
}
</style>
