// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card[data-v-66ffc4f7] {
    margin: 0;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .125);
    max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/PatientTOS.vue"],"names":[],"mappings":";AAuDA;IACA,SAAA;IACA,gBAAA;IACA,qCAAA;IACA,eAAA;AACA","sourcesContent":["<template>\n    <div class=\"card\">\n        <div\n            class=\"card-header\"\n        >\n            <a\n                href=\"#\"\n                @click.prevent=\"isOpened = !isOpened\"\n            >\n              {{ $t('_216')}}\n            </a>\n        </div>\n\n        <div class=\"card-body\" v-show=\"isOpened\">\n            <p>{{ $t('_0') }}</p>\n            <p>{{ $t('_1') }}</p>\n            <p>{{ $t('_2') }}</p>\n\n            <ul>\n                <li>{{ $t('_3') }}</li>\n                <li>{{ $t('_4') }}</li>\n                <li>{{ $t('_5') }}</li>\n                <li>{{ $t('_6') }}</li>\n                <li>{{ $t('_7') }}</li>\n                <li>{{ $t('_8') }}</li>\n                <li>{{ $t('_9') }}</li>\n            </ul>\n\n            <p>{{ $t('_10') }}</p>\n            <p>{{ $t('_11') }}</p>\n            <p>{{ $t('_12') }}</p>\n            <p>{{ $t('_13') }}</p>\n            <p>{{ $t('_14') }}</p>\n            <p>{{ $t('_15') }}</p>\n            <p>{{ $t('_16') }}</p>\n            <p>{{ $t('_17') }}</p>\n\n            <a href=\"#\" @click.prevent=\"isOpened = false\">\n                {{ $t('_close') }}\n            </a>\n        </div>\n    </div>\n</template>\n\n<script>\nexport default {\n    data() {\n        return {\n            isOpened: false,\n        }\n    }\n};\n</script>\n\n<style scoped>\n.card {\n    margin: 0;\n    box-shadow: none;\n    border: 1px solid rgba(0, 0, 0, .125);\n    max-width: 100%;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
