import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DateTime } from 'luxon';
import PatientSignUp from '@pages/PatientSignUp.vue';
import PatientCard from '@pages/PatientCard.vue';
import PatientMedicalUpdate from '@pages/PatientMedicalUpdate.vue';
import ProfessionnalLogBook from '@pages/ProfessionnalLogBook.vue';
import PatientLogbook from '@pages/PatientLogbook.vue';
import Questionnaires from '@pages/Questionnaires.vue';
import PreRegistrationHcp from '@pages/PreRegistrationHcp.vue';
import ParrainageHcp from '@pages/ParrainageHcp.vue';
import ModalQuestions from '@modals/ModalQuestions.vue';
import ModalCgu from '@modals/ModalCgu.vue';
import IconBook from '@components/icons/IconBook.vue';
import IconPaper from '@components/icons/IconPaper.vue';
import IconGrow from '@components/icons/IconGrow.vue';
import IconArrowDown from '@components/icons/IconArrowDown.vue';
import IconWhitePlus from '@components/icons/IconWhitePlus.vue';
import IconWhiteMinus from '@components/icons/IconWhiteMinus.vue';
import Input from '@components/inputs/Input.vue';
import Checkbox from '@components/inputs/Checkbox.vue';
import Radio from '@components/inputs/Radio.vue';
import InputGroup from '@components/inputs/InputGroup.vue';
import SelectGlobal from '@components/inputs/SelectGlobal.vue';
import Axios from 'axios';
import tooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';

Vue.prototype.$axios = Axios;
Vue.prototype.$luxon = DateTime;

Vue.use(tooltip);
Vue.use(VueI18n);
Vue.component('PatientSignUp', PatientSignUp);
Vue.component('PatientCard', PatientCard);
Vue.component('PatientMedicalUpdate', PatientMedicalUpdate);
Vue.component('Questionnaires', Questionnaires);
Vue.component('ModalQuestions', ModalQuestions);
Vue.component('ModalCgu', ModalCgu);
Vue.component('ProfessionnalLogBook', ProfessionnalLogBook);
Vue.component('PatientLogbook', PatientLogbook);
Vue.component('PreRegistrationHcp', PreRegistrationHcp);
Vue.component('ParrainageHcp', ParrainageHcp);

// Icons
Vue.component('IconBook', IconBook);
Vue.component('IconPaper', IconPaper);
Vue.component('IconGrow', IconGrow);
Vue.component('IconArrowDown', IconArrowDown);
Vue.component('IconWhitePlus', IconWhitePlus);
Vue.component('IconWhiteMinus', IconWhiteMinus);

// Inputs 
Vue.component('Input', Input);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('InputGroup', InputGroup);
Vue.component('SelectGlobal', SelectGlobal);

// Vue2 Time Picker
Vue.component('VueTimepicker', VueTimepicker);


const el = document.querySelector('#__app');

export const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'fr',
})

const loadedLanguages = [] // our default language that is preloaded
function setI18nLanguage(lang) {
  i18n.locale = lang
  Axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang = 'fr') {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return import(`@locales/${lang}/translation.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  }).catch(err => console.error('Erreur chargement langue :', err));
}


if (el) {
  const app = new Vue({ i18n });
  const interval = setInterval(() => {
    if (translationReady) {
      loadLanguageAsync(sessionStorage.language).then(() => app.$mount(el));
      clearInterval(interval);
    }
  }, 250);
}
