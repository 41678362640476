<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <InfoPatient v-if="user" :user="user" :uuid="uuid" :context="context" :editModeActivated="editModeActivated"  :qrcode="qrcode" class="col-12"/>
      </div>
      <div class="col-md-7">
        <MedicalProfil v-if="user" :user="user" :uuid="uuid"  :context="context" :patient_id="patient_id" class="col-12"/>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-5">
        <ContactForm v-if="user" :user="user" :uuid="uuid" :context="context" :data_wilayas="wilayas" :data_towns="towns" :editModeActivated="editModeActivated" class="col-12 pt-3"/>
      </div>
      <div class="col-md-7">
        <ProgrammePatientJourney  v-if="user" :user="user" :uuid="uuid"  :context="context" :data_thematics="thematics" :editModeActivated="editModeActivated" class="col-12 pt-3"/>
        <FormationsPatient v-if="user && user.has_diabete && isDiabCard" :user="user" :uuid="uuid"  :context="context" :editModeActivated="editModeActivated" class="col-12 mt-3"/>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-12">
        <JourneyEducationalPrograms v-if="user" :user="user" :uuid="uuid" :context="context" :communication_list="communication_list" />
      </div>
    </div>
    <div class="row pt-3">
      <div v-if="isCommunicationEnabled(3)" class="col-md-5">
        <HistorySms v-if="user &&user.has_diabete && isDiabCard" :user="user" :uuid="uuid"  :context="context" class="col-12 pt-3" />
      </div>
      <div class="col-md-7">
        <DigitalTherapeuticEducation v-if="user && user.has_diabete && isDiabCard" :user="user" :uuid="uuid"  :context="context" class="col-12 pt-3" />
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-5" v-if="isCommunicationEnabled(4)">
        <HistoryMail v-if="user" :user="user" :uuid="uuid"  :context="context" class="col-12 pt-3"/>
      </div>
      <div class="col-md-7">
        <TeleEducationHistory v-if="showTeleEducationAndFaceToFaceHistory && user.has_diabete && isDiabCard && isCommunicationEnabled(2)" :user="user" :uuid="uuid"  :context="context"  class="col-12 pt-3"/>
        <HistoryFtfSession v-if="showTeleEducationAndFaceToFaceHistory && user.has_diabete && isDiabCard && isCommunicationEnabled(1)" :user="user" :uuid="uuid"  :context="context" class="col-12 mt-3"/>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPatient from '@components/forms/InfoPatient.vue';
import MedicalProfil from "@components/forms/MedicalProfil.vue";
import ContactForm from '@components/forms/ContactForm.vue';
import JourneyEducationalPrograms from '@components/forms/JourneyEducationalPrograms.vue';
import ProgrammePatientJourney from '@components/forms/ProgrammePatientJourney.vue';
import HistorySms from "@components/forms/HistorySms.vue";
import DigitalTherapeuticEducation from "@components/forms/DigitalTherapeuticEducation.vue";
import FormationsPatient from "@components/forms/FormationsPatient.vue";
import HistoryMail from "@components/forms/HistoryMail.vue";
import TeleEducationHistory from "@components/forms/TeleEducationHistory.vue";
import HistoryFtfSession from "@components/forms/HistoryFtfSession.vue";
import OtherAction from "@components/forms/OtherAction.vue";

export default {
  name: "PatientCard",

  components: {
    InfoPatient,
    MedicalProfil,
    ContactForm,
    JourneyEducationalPrograms,
    ProgrammePatientJourney,
    FormationsPatient,
    HistorySms,
    DigitalTherapeuticEducation,
    HistoryMail,
    TeleEducationHistory,
    HistoryFtfSession,
    OtherAction,
  },

  props: {
    context: {
      type: String,
      required: true,
    },

    wilayas: {
      type: Array,
      required: true,
    },

    towns: {
      type: Array,
      required: true,
    },

    thematics: {
      type: Array,
      required: true,
    },
  },


  data() {
    return {
      uuid: sessionStorage.getItem('uuid'),
      patient_id: sessionStorage.getItem('patient_id'),
      user: null,
      hcp: null,
      events : [],
      showTeleEducationAndFaceToFaceHistory: false,
      edit_mode_activated: false,
      isHtaCard: false,
      isDiabCard: false,
      qrcode: '',
      communication_list : []
    }
  },

  mounted() {
    this.isHtaCard = sessionStorage.getItem('fds') === 'hta';
    this.isDiabCard = sessionStorage.getItem('fds') === 'diabete';
    const endpoint = 'ws/get_infos_patient.php';
    const params = [
      '?patient_id=' + this.patient_id +
      '&uuid=' + this.uuid +
      '&type_patient=' + sessionStorage.getItem('fds'),
    ];
    
    this.$axios.get(endpoint + params)
      .then(response => {
        this.user = response.data.data;
        if (this.user.diabetes.diabetes_active) {
          if (1 !== Object.keys(this.user.diabetes.treatments).length ||
            ('Lantus' !== Object.keys(this.user.diabetes.treatments)[0] && 1 === Object.keys(this.user.diabetes.treatments).length)
          ) {
            this.showTeleEducationAndFaceToFaceHistory = true;
          }
        }
      })
      .catch(e => {
        console.error(e);
      });


      this.$axios.get('ws/get_qrcode_patient.php'+params)
            .then(response => {
              this.qrcode = response.data.data.password_reset_link;
            })
            .catch((error) => {
              console.log(error);
            });
      window.onbeforeunload = () => {
        if (this.edit_mode_activated) {
          return true;
        }
        return undefined;
      }
      this.$axios.get('/ws/get_all_communications.php')
      .then(response => {
              this.communication_list = response.data.data;
            })
            .catch((error) => {
              console.log(error);
            });
      
  },
  methods: {
    editModeActivated: function() {
      this.edit_mode_activated = this.edit_mode_activated ? false : true;
    },
    isCommunicationEnabled(id) {
      const conditionObj = this.communication_list.find(cond => cond.id === id);
      return conditionObj && conditionObj.enabled ;
    },
  }
}
</script>

<style scoped>

</style>
