<template>
    <div>
        <Modal v-if="isOpen" :is-open="isOpen" title="Objectifs glycémiques" @close="$emit('close')">
            <div class='objectives-modal'>
                <div v-for="(value, key, index) in logbookObjectives" :key="index">
                    <div v-if="logbookObjectives[key]">
                        <span role="button">
                            <div 
                            data-toggle="collapse" 
                            :data-target="'#collapsedText' + index" 
                            aria-expanded="false" 
                            aria-controls="collapsedText" 
                            class="d-flex align-items-center justify-content-between" 
                            @click="toggleItem(index)"
                            >
                                <p>{{$t('_'+ key)}}</p>
                                <icon-arrow-down :class="isActive(index) ? 'transform-arrow' : ''"></icon-arrow-down>
                            </div>
                        </span>
                        <hr>
                        <div class="collapse" :class="index === 0 ? 'show' : ''" :id="'collapsedText' + index">
                            <div v-if="logbookObjectives[key]['increase_max']" class="text-white rounded p-4 mb-3 blue-color">
                                <p>
                                    {{$t('_upon')}} <span class="font-weight-bold">{{ logbookObjectives[key]['increase_max'] ? logbookObjectives[key]['increase_max'].above : '' }}</span> mg/dL:
                                {{$t('_rise_to')}} <span class="font-weight-bold">{{ logbookObjectives[key]['increase_max'] ?  logbookObjectives[key]['increase_max'].U : '' }}</span> U
                                </p>
                            </div>
                            <div v-if="logbookObjectives[key]['increase']" class="text-white rounded p-4 mb-3 blue-color">
                                <p>
                                    {{$t('_from_capital')}} <span class="font-weight-bold">{{ logbookObjectives[key]['increase'].from }}</span> mg/dL
                                    {{$t('_to')}}  <span class="font-weight-bold">{{ logbookObjectives[key]['increase'].to }}</span> mg/dL: 
                                    {{$t('_rise_to')}} <span class="font-weight-bold">{{ logbookObjectives[key]['increase'].U }}</span> U
                                </p>
                            </div>
                            <div v-if="logbookObjectives[key]['normal']" class="text-white rounded p-4 mb-3 green-color">
                                <p>
                                    {{$t('_from_capital')}} <span class="font-weight-bold">{{ logbookObjectives[key]['normal'].from }}</span> mg/dL
                                    {{$t('_to')}}  <span class="font-weight-bold">{{ logbookObjectives[key]['normal'].to }}</span> mg/dL: 
                                    {{$t('_no_modifications')}}
                                </p>
                            </div>
                            <div v-if="logbookObjectives[key]['decrease']" class="text-white rounded p-4 mb-3 red-color">
                                <p>
                                    {{$t('_from_capital')}} <span class="font-weight-bold">{{ logbookObjectives[key]['decrease'].from }}</span> mg/dL
                                    {{$t('_to')}}  <span class="font-weight-bold">{{ logbookObjectives[key]['decrease'].to }}</span> mg/dL: 
                                    {{$t('_low_to')}} <span class="font-weight-bold">{{ logbookObjectives[key]['decrease'].U }}</span> U
                                </p>
                            </div>
                            <div v-if="logbookObjectives[key]['decrease_max']" class="text-white rounded p-4 mb-3 red-color">
                                <p>
                                    {{$t('_under')}} <span class="font-weight-bold">{{ logbookObjectives[key]['decrease_max'].below }}</span> mg/dL:
                                {{$t('_low_to')}} <span class="font-weight-bold">{{ logbookObjectives[key]['decrease_max'].U }}</span> U
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <p class="font-weight-bold">{{$t('_logbook_title_pro_hba1c')}}</p>
                    <p> {{$t('_between')}} {{ logbookHba1c.between }} % {{$t('_and')}} {{ logbookHba1c.and }} %</p>
                </div>
            </div>
          
        </Modal>
    </div>
</template>

<script>
import Modal from '@components/modals/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        data: {
            type: Object
        },
        isOpen: {
            type: Boolean,
            default: false,
        }, 
        logbookObjectives: {
            type: Object
        }, 
        logbookHba1c: {
            type: Object
        }
    },
    data() {
        return {
            toggled: []
        }
    },
    mounted() {
        this.toggleItem(0);
    },
    methods: {
        isActive (item) {
            return this.toggled.indexOf(item) >= 0
        },
        toggleItem(item) {
            const index = this.toggled.indexOf(item)

            if (index >= 0) {
                this.toggled.splice(index, 1)
                return
            }

            this.toggled.push(item)
        },
    }
};
</script>

<style scoped>
.transform-arrow {
    transition: all 0.2s ease-in-out;
    transform: rotate(180deg);
}

.green-color {
    background-color: #1EC66A;
}

.red-color {
    background-color: #870304;
}

.blue-color {
    background-color: #0C8FBA;
}

.objectives-modal {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
