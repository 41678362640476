var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section" }, [
    _c("p", [_vm._v(_vm._s(_vm.$t("_parrainer")))]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "bg-white p-4 rounded-lg",
        attrs: {
          enctype: "multipart/form-data",
          method: "post",
          action: "/professionnel/ws/parrainage_confrere.php",
        },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return ((event) => !_vm.loading && _vm.submit(event)).apply(
              null,
              arguments
            )
          },
        },
      },
      [
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Input", {
              attrs: {
                label: _vm.$t("_118"),
                name: "email",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.email,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email",
              },
            }),
            _vm._v(" "),
            _c(
              "InputGroup",
              {
                attrs: { label: _vm.$t("_parrainer_programme"), required: "" },
              },
              [
                _c("Radio", {
                  attrs: {
                    value: "hta",
                    name: "program",
                    id: "hta",
                    label: _vm.$t("_222"),
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
                _vm._v(" "),
                _c("Radio", {
                  attrs: {
                    value: "diab",
                    name: "program",
                    id: "diab",
                    label: _vm.$t("_162"),
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
                _vm._v(" "),
                _c("Radio", {
                  attrs: {
                    value: "diab-hta",
                    name: "program",
                    id: "diab-hta",
                    label: _vm.$t("_diab_hta"),
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("_parrainer_mail")))]),
            _vm._v(" "),
            _c("br"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Submit", {
              staticClass: "text-center",
              attrs: { label: _vm.$t("_parrainer_valider") },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }