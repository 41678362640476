<template>
  <form enctype="multipart/form-data"
        class="bg-white p-4 rounded-lg"
        method="post"
        :action="'/ws/update_patient_medical_data.php'"
        @submit.prevent="event => !loading && submit(event)"

  >
    <PatientMedicalFormGroup :form="form.medical"
                             :treatments="treatments"
                             :programs="programs"
                             :rulesData="rulesData"
                             :loading="loading"
                             :edit="true"
                             @programChange="onProgramChange()"
                             :hcp="hcp"
                             :context="context"
                             :canView="canView"
                             :notMatch="notMatch"
    />

    <fieldset :disabled="loading">
      <legend>
        <h2 class="mb-0">{{ $t("_101") }}</h2>
      </legend>

      <InputGroup :label="$t('_190')" bold>
        <PatientTOS class="mt-2 mb-3"/>
      </InputGroup>

      <hr/>

      <InputGroup :label="$t('_191')" bold>
        <p class="mb-0">
          1.
          <a
            @click="getPdf()"
            class="txt-violet"
          >
            {{ $t('_217') }}.
          </a>
        </p>

        <FileUpload
          v-model="form.consentForm"
          :label="$t('_192')"
          name="consentForm"
          accept="image/*, application/pdf"
          :required="context === 'hcp' ? false : true"
        />
      </InputGroup>

      <hr/>

      <!-- ConsentPrograms -->
      <ConsentPrograms
        :form="this.form"
        :programs="this.programs"
      />

    </fieldset>

    <fieldset :disabled="loading">
      <Submit
        class="text-center"
        :label="$t('_195')"
      />
    </fieldset>
    <PatientSignUpSuccess
        :isOpen="formSuccess"
        @close="redirect"
        :context="context"
        :solutions=" form.medical.solutions"
    />
  </form>


</template>

<script>
import PatientMedicalFormGroup from '@components/forms/PatientMedicalFormGroup.vue';
import FileUpload from '@components/inputs/FileUpload.vue';
import InputGroup from '@components/inputs/InputGroup.vue';
import Input from '@components/inputs/Input.vue';
import Checkbox from '@components/inputs/Checkbox.vue';
import Submit from '@components/inputs/Submit.vue';
import PatientTOS from '@components/PatientTOS.vue';
import PatientSignUpSuccess from '@components/modals/PatientSignUpSuccess.vue';
import ConsentPrograms from '@components/forms/ConsentPrograms.vue'
import {toFormData} from '@helpers';


export default {
  components: {
    PatientMedicalFormGroup,
    FileUpload,
    InputGroup,
    Input,
    Submit,
    PatientTOS,
    Checkbox,
    PatientSignUpSuccess,
    ConsentPrograms,
  },

  props: {
    hcp: {
      type: Object,
      required: true,
    },

    treatments: {
      type: Object,
      required: true,
    },

    programs: {
      type: Array,
      required: true,
    },

    context: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    solutions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      canView: {
              diabeteData: '',
              htaData: '',
            },
      notMatch: {
              diabete: '',
              hta: '',
            },

      form: {
        consentForm: null,

        medical: {
          diabetes: {
            code: this.hcp.code,
            active: false,
            treatments: [],
            programs: [],
            type: '',
            insulinLessThan3Months: '',
          },
          hypertension: {
            code: this.hcp.code,
            active: false,
            treatments: [],
            programs: [],
            data: {
              antihypertensive_less_than_6_months: '',
              smoking: '',
              illness : ''
            },
        },
          solutions: [],
          createdBy: this.hcp.uuid,
          lang: this.hcp.lang,
          patient_id: '',
          origin: ''
        },

      },

      rulesData: {
        cellphone: '',
        email: '',
      },

      formSuccess: false,

      errors: null,

      selectedPrograms: ''
    };

  },
  mounted: function () {
    this.form.medical.patient_id = sessionStorage.getItem('patient_id')
    this.getPatientDatas(this.data)
    this.form.medical.solutions = this.data.solutions
    this.form.medical.origin = this.hcp.origin
  },

  computed: {
    prefix() {
      return this.context === 'hcp' ? '/professionnel' : '/cro';
    },
  },

  methods: {
    getPatientDatas(data) {
      if (data.diabetes_active && sessionStorage.getItem('fds') === 'diabete') {
        if (data.hasOwnProperty('userInfo')) {
          this.form.medical.diabetes.code = data.userInfo.code;
        }
        this.form.medical.diabetes.active = data.diabetes_active
        this.form.medical.diabetes.treatments = data.diabetes.treatments.map(ele=> Number(ele))
        this.form.medical.diabetes.programs = data.diabetes.programs
        this.form.medical.diabetes.type = data.diabetes.type
        this.form.medical.diabetes.insulinLessThan3Months = data.diabetes.insulin_less_than_3_months ? '1' : '0'
      }

      if (data.hypertension_active && sessionStorage.getItem('fds') === 'hta') {
        this.form.medical.hypertension.active = data.hypertension_active
        this.form.medical.hypertension.treatments = data.hypertension.treatments
        this.form.medical.hypertension.programs = data.hypertension.programs
        this.form.medical.hypertension.data.antihypertensive_less_than_6_months = data.hypertension.antihypertensive_less_than_6_months.toString()
        this.form.medical.hypertension.data.smoking = data.hypertension.smoking.toString()
      }
    },

    getPdf() {
      this.$axios.post(this.prefix + '/consent_cgu_patient.php', toFormData(this.form), {responseType: 'arraybuffer'})
        .then((response) => {
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Consentement.pdf';
          link.click();
        });
    },

    submit(event) {
      this.formSuccess = false;
      this.loading = true;

      this.$axios.post(event.target.action, toFormData(this.form))
        .then(response => {
          this.formSuccess = true;
          this.loading = false;
          history.back();
        })
        .catch(e => {
          const errors = e.response.data;

          for (const input in errors) {
              window.showAlert(
                  input,
                  // TODO: implement error messages with translation
                  // library
                  `${input}: ${errors[input].join(', ')}`
              );
          }

          this.errors = errors;
          this.loading = false;
        });
    },
    onProgramChange() {
      this.mergeSelectedPrograms()
    },

    mergeSelectedPrograms() {
      let array =  this.form.medical.diabetes.programs.concat(this.form.medical.hypertension.programs)
      let programs = Object.entries(this.programs)
      let selected =  []
      array.forEach((item) => {
        programs.forEach((element) => {
          if (element.includes(String(item))) {
            selected.push(element[0])
          }
        });
      })

      this.selectedPrograms = selected.length === 1
        ? this.$i18n.t('_213', {program: `${selected[0]}`})
        : this.$i18n.t('_214', {programs: `${selected.join(', ')}`})
    },

    redirect() {
        window.location = this.prefix + '/home.php';
    }
  },
};
</script>
