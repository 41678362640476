var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen === true
    ? _c("div", [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "button_modal mt-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: {
                      href: "/commun/disconnect.php?redirect=/professionnel/",
                    },
                  },
                  [_vm._v("\tx " + _vm._s(_vm.$t("_close")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.validCgu()
                      },
                    },
                  },
                  [_vm._v("\t✔ Valider")]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row w-100" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _c("h2", [
          _vm._v(
            "\n              Rejoignez le programme Diab eCare offrant à vos patients inscrits des solutions et services personnalisés avec :\n            "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n                Intégration d'un carnet glycémique digital comme nouveau service de monitoring, avec l'amélioration du suivi à travers des graphiques spécifiques.\n              "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                Intégration d'un service de continuous glucose monitoring pour une surveillance en temps réel.\n              "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                Différentiation du contenu éducatif pour les patients naïfs à l'insuline pour un meilleur impact\n              "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                Ajout de nouveaux canaux d'éducation thérapeutique pour une meilleure expérience d'apprentissage (articles web, podcasts, brochures)\n              "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n              Nous vous invitons à consulter les conditions générales d'utilisation du programme support patient Diab eCare et à y adhérer en cliquant sur la case à cocher ci-dessous :\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "row d-flex flex-column" }, [
          _c("img", {
            staticClass: "logo1",
            attrs: { src: require("@img/DiabeCare_logoH__1_.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "logo2",
            attrs: { src: require("@img/carnet_glycémique.jpg"), alt: "" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row w-100" }, [
      _c(
        "div",
        {
          staticClass: "input input-colapsed",
          staticStyle: { "max-width": "initial", margin: "auto" },
        },
        [
          _c("input", {
            staticClass: "input-body",
            attrs: {
              type: "checkbox",
              name: "agreementDiabete",
              id: "inputCheckboxAgreementDiabete",
              value: "1",
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "input-label",
              attrs: { for: "inputCheckboxAgreementDiabete" },
            },
            [
              _vm._v(" J'accepte les "),
              _c(
                "a",
                {
                  attrs: {
                    href: "/documents/cgu_professionnel_new.pdf",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    " Conditions Générales d'utilisations Programmes Support Patients "
                  ),
                ]
              ),
              _vm._v(" et confirme mon inscription au programme Diab eCare."),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }