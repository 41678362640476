var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _vm.user
            ? _c("InfoPatient", {
                staticClass: "col-12",
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  editModeActivated: _vm.editModeActivated,
                  qrcode: _vm.qrcode,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-7" },
        [
          _vm.user
            ? _c("MedicalProfil", {
                staticClass: "col-12",
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  patient_id: _vm.patient_id,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-3" }, [
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _vm.user
            ? _c("ContactForm", {
                staticClass: "col-12 pt-3",
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  data_wilayas: _vm.wilayas,
                  data_towns: _vm.towns,
                  editModeActivated: _vm.editModeActivated,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-7" },
        [
          _vm.user
            ? _c("ProgrammePatientJourney", {
                staticClass: "col-12 pt-3",
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  data_thematics: _vm.thematics,
                  editModeActivated: _vm.editModeActivated,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.user && _vm.user.has_diabete && _vm.isDiabCard
            ? _c("FormationsPatient", {
                staticClass: "col-12 mt-3",
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  editModeActivated: _vm.editModeActivated,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-3" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm.user
            ? _c("JourneyEducationalPrograms", {
                attrs: {
                  user: _vm.user,
                  uuid: _vm.uuid,
                  context: _vm.context,
                  communication_list: _vm.communication_list,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-3" }, [
      _vm.isCommunicationEnabled(3)
        ? _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _vm.user && _vm.user.has_diabete && _vm.isDiabCard
                ? _c("HistorySms", {
                    staticClass: "col-12 pt-3",
                    attrs: {
                      user: _vm.user,
                      uuid: _vm.uuid,
                      context: _vm.context,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-7" },
        [
          _vm.user && _vm.user.has_diabete && _vm.isDiabCard
            ? _c("DigitalTherapeuticEducation", {
                staticClass: "col-12 pt-3",
                attrs: { user: _vm.user, uuid: _vm.uuid, context: _vm.context },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-3" }, [
      _vm.isCommunicationEnabled(4)
        ? _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _vm.user
                ? _c("HistoryMail", {
                    staticClass: "col-12 pt-3",
                    attrs: {
                      user: _vm.user,
                      uuid: _vm.uuid,
                      context: _vm.context,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-7" },
        [
          _vm.showTeleEducationAndFaceToFaceHistory &&
          _vm.user.has_diabete &&
          _vm.isDiabCard &&
          _vm.isCommunicationEnabled(2)
            ? _c("TeleEducationHistory", {
                staticClass: "col-12 pt-3",
                attrs: { user: _vm.user, uuid: _vm.uuid, context: _vm.context },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showTeleEducationAndFaceToFaceHistory &&
          _vm.user.has_diabete &&
          _vm.isDiabCard &&
          _vm.isCommunicationEnabled(1)
            ? _c("HistoryFtfSession", {
                staticClass: "col-12 mt-3",
                attrs: { user: _vm.user, uuid: _vm.uuid, context: _vm.context },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }