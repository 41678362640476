var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    { attrs: { disabled: _vm.loading } },
    [
      _c("legend", [
        _c("h2", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.$t("_83")))]),
      ]),
      _vm._v(" "),
      _c(
        "InputGroup",
        { attrs: { label: _vm.$t("_147"), required: "" } },
        [
          _c("Checkbox", {
            attrs: {
              name: "hasDiabetes",
              id: "conditionDiabetes",
              label: _vm.$t("_148"),
              disabled:
                !_vm.CanInsertPatientCroOrHcp ||
                (_vm.canView.diabeteData != "" && _vm.canView.diabeteData) ||
                (_vm.notMatch.diabete != "" && _vm.notMatch.diabete),
            },
            on: {
              change: () => {
                if (!_vm.isLoadingSolutions) _vm.disableDefaultSolution(5)
              },
            },
            model: {
              value: _vm.form.diabetes.active,
              callback: function ($$v) {
                _vm.$set(_vm.form.diabetes, "active", $$v)
              },
              expression: "form.diabetes.active",
            },
          }),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              name: "hasHypertension",
              id: "conditionHypertension",
              label: _vm.$t("_149"),
              disabled:
                _vm.context === "cro" ||
                !_vm.hcp.has_hta ||
                (_vm.canView.htaData != "" && _vm.canView.htaData) ||
                (_vm.notMatch.hta != "" && _vm.notMatch.hta) ||
                _vm.isPatientMinor,
            },
            on: {
              change: () => {
                if (!_vm.isLoadingSolutions) _vm.disableDefaultSolution(7)
              },
            },
            model: {
              value: _vm.form.hypertension.active,
              callback: function ($$v) {
                _vm.$set(_vm.form.hypertension, "active", $$v)
              },
              expression: "form.hypertension.active",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.diabetes.active ||
      (_vm.canView.diabeteData != "" && _vm.canView.diabeteData)
        ? _c("div", [
            _c("span", [
              _c("div", [
                _c("legend", [
                  _vm.notMatch.diabete == ""
                    ? _c(
                        "h2",
                        {
                          staticClass: "mb-0",
                          class: { "disabled-text": !_vm.form.diabetes.active },
                        },
                        [_vm._v(_vm._s(_vm.$t("_profil_medical_diabete")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notMatch.diabete != "" && _vm.notMatch.diabete
                    ? _c("h2", { staticClass: "mb-0 disabled-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("_profil_medical_diabete_already")) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.form.diabetes.active ||
            (_vm.canView.diabeteData != "" && _vm.canView.diabeteData)
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c("Input", {
                          attrs: {
                            label: _vm.$t("_166"),
                            name: "hcpCode",
                            readonly: _vm.context === "hcp",
                            "custom-margin": true,
                            required: "",
                          },
                          model: {
                            value: _vm.form.diabetes.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.diabetes, "code", $$v)
                            },
                            expression: "form.diabetes.code",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "InputGroup",
                          { attrs: { label: _vm.$t("_150"), required: "" } },
                          [
                            _c("Radio", {
                              attrs: {
                                value: 1,
                                name: "diabetesType",
                                id: "diabetesType1",
                                label: _vm.$t("_151"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "type", $$v)
                                },
                                expression: "form.diabetes.type",
                              },
                            }),
                            _vm._v(" "),
                            _c("Radio", {
                              attrs: {
                                value: 2,
                                name: "diabetesType",
                                id: "diabetesType2",
                                label: _vm.$t("_152"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "type", $$v)
                                },
                                expression: "form.diabetes.type",
                              },
                            }),
                            _vm._v(" "),
                            _c("Radio", {
                              attrs: {
                                value: 3,
                                name: "diabetesType",
                                id: "diabetesType3",
                                label: _vm.$t("_153"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "type", $$v)
                                },
                                expression: "form.diabetes.type",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "InputGroup",
                          { attrs: { label: _vm.$t("_154"), required: "" } },
                          [
                            _c("Checkbox", {
                              attrs: {
                                value: 1,
                                name: "diabetesTreatments[]",
                                id: "diabetesTreatment1",
                                label: _vm.$t("_155"),
                                disabled:
                                  _vm.form.diabetes.treatments.includes(3) ||
                                  (_vm.canView.diabeteData != "" &&
                                    _vm.canView.diabeteData),
                                clear: _vm.form.diabetes.treatments.includes(3),
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.treatments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "treatments", $$v)
                                },
                                expression: "form.diabetes.treatments",
                              },
                            }),
                            _vm._v(" "),
                            _c("Checkbox", {
                              attrs: {
                                value: 2,
                                name: "diabetesTreatments[]",
                                id: "diabetesTreatment2",
                                label: _vm.$t("_156"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.uncheckProgram },
                              model: {
                                value: _vm.form.diabetes.treatments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "treatments", $$v)
                                },
                                expression: "form.diabetes.treatments",
                              },
                            }),
                            _vm._v(" "),
                            _c("Checkbox", {
                              attrs: {
                                value: 3,
                                name: "diabetesTreatments[]",
                                id: "diabetesTreatment3",
                                label: _vm.$t("_157"),
                                disabled: !false,
                                clear: !false,
                              },
                              model: {
                                value: _vm.form.diabetes.treatments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.diabetes, "treatments", $$v)
                                },
                                expression: "form.diabetes.treatments",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "InputGroup",
                          { attrs: { label: _vm.$t("_158"), required: "" } },
                          [
                            _c("Radio", {
                              attrs: {
                                value: "1",
                                name: "insulinLessThan3Months",
                                id: "insulinTrue",
                                label: _vm.$t("_159"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.insulinLessThan3Months,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.diabetes,
                                    "insulinLessThan3Months",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.diabetes.insulinLessThan3Months",
                              },
                            }),
                            _vm._v(" "),
                            _c("Radio", {
                              attrs: {
                                value: "0",
                                name: "insulinLessThan3Months",
                                id: "insulinFalse",
                                label: _vm.$t("_160"),
                                disabled:
                                  _vm.canView.diabeteData != "" &&
                                  _vm.canView.diabeteData,
                              },
                              on: { change: _vm.preChecked },
                              model: {
                                value: _vm.form.diabetes.insulinLessThan3Months,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.diabetes,
                                    "insulinLessThan3Months",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.diabetes.insulinLessThan3Months",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("legend", [
                      _c("h2", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("_98"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 asteriskRequired" }, [
                          _vm._v(_vm._s(_vm.$t("_215"))),
                        ]),
                        _vm._v(" "),
                        _vm.getSolutionByGroup("has_diabete", 1).length > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 col-lg-4" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "label-multisolutions solution-education",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("_99")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "InputGroup",
                                  { attrs: { required: "" } },
                                  _vm._l(
                                    _vm.getSolutionByGroup("has_diabete", 1),
                                    function (solution) {
                                      return _c("Checkbox", {
                                        key: `educationSolution${solution.id}`,
                                        attrs: {
                                          value: solution.id,
                                          name: "educationSolutions",
                                          tooltip: _vm.helperTooltip(
                                            solution.id
                                          ),
                                          id: `monitoringSolution${solution.id}`,
                                          label: solution.name,
                                          disabled: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                          clear: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                        },
                                        model: {
                                          value: _vm.form.solutions,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "solutions", $$v)
                                          },
                                          expression: "form.solutions",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getSolutionByGroup("has_diabete", 2).length > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 col-lg-4" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "label-multisolutions solution-monitoring",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("_100")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "InputGroup",
                                  _vm._l(
                                    _vm.getSolutionByGroup("has_diabete", 2),
                                    function (solution) {
                                      return _c("Checkbox", {
                                        key: `monitoringSolution${solution.id}`,
                                        attrs: {
                                          value: solution.id,
                                          name: "monitoringSolutions[]",
                                          tooltip: _vm.helperTooltip(
                                            solution.id
                                          ),
                                          id: `monitoringSolution${solution.id}`,
                                          label: solution.name,
                                          disabled: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                          clear: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                        },
                                        model: {
                                          value: _vm.form.solutions,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "solutions", $$v)
                                          },
                                          expression: "form.solutions",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "InputGroup",
                      { attrs: { label: _vm.$t("_161"), required: "" } },
                      [
                        _c("Checkbox", {
                          attrs: {
                            value: 1,
                            name: "diabetesPrograms[]",
                            id: "diabetesProgram1",
                            label: _vm.$t("_162"),
                            tooltip: _vm.$t("tooltip_diab_ecare"),
                            disabled: !_vm.form.diabetes.active,
                            clear: !_vm.form.diabetes.active,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$emit("programChange")
                            },
                          },
                          model: {
                            value: _vm.form.diabetes.programs,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.diabetes, "programs", $$v)
                            },
                            expression: "form.diabetes.programs",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.form.hypertension.active
        ? _c("div", [
            _c("span", [
              _c("div", [
                _c("legend", [
                  _vm.notMatch.hta == "" &&
                  !_vm.isPatientMinor &&
                  _vm.context === "hcp"
                    ? _c(
                        "h2",
                        {
                          staticClass: "mb-0",
                          class: {
                            "disabled-text": !_vm.form.hypertension.active,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("_profil_medical_hypertension")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notMatch.hta != "" &&
                  _vm.notMatch.hta &&
                  _vm.context === "hcp"
                    ? _c("h2", { staticClass: "mb-0 disabled-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("_profil_medical_hypertension_confrere")
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPatientMinor && _vm.context === "hcp"
                    ? _c("h2", { staticClass: "mb-0 disabled-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("_profil_medical_hypertension_ans")) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.context === "cro" && !_vm.canView.htaData
                    ? _c("h2", { staticClass: "mb-0 disabled-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("_profil_medical_hypertension_droits")
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.context === "cro" &&
                  _vm.canView.htaData != "" &&
                  _vm.canView.htaData
                    ? _c("h2", { staticClass: "mb-0 disabled-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("_profil_medical_hypertension_hcp")) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.form.hypertension.active
              ? _c("div", [
                  _c(
                    "div",
                    { attrs: { id: "hypertension" } },
                    [
                      _c("Input", {
                        attrs: {
                          label: _vm.$t("_166"),
                          name: "hcpCode",
                          readonly: _vm.context === "hcp",
                          "custom-margin": true,
                          required: "",
                        },
                        model: {
                          value: _vm.form.hypertension.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.hypertension, "code", $$v)
                          },
                          expression: "form.hypertension.code",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "InputGroup",
                        {
                          attrs: { label: _vm.$t("_tabagisme"), required: "" },
                        },
                        [
                          _c("Radio", {
                            attrs: {
                              value: "1",
                              name: "smoking",
                              id: "smoking1",
                              label: _vm.$t("_yes"),
                            },
                            model: {
                              value: _vm.form.hypertension.data.smoking,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.hypertension.data,
                                  "smoking",
                                  $$v
                                )
                              },
                              expression: "form.hypertension.data.smoking",
                            },
                          }),
                          _vm._v(" "),
                          _c("Radio", {
                            attrs: {
                              value: "0",
                              name: "smoking",
                              id: "smoking0",
                              label: _vm.$t("_no"),
                            },
                            model: {
                              value: _vm.form.hypertension.data.smoking,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.hypertension.data,
                                  "smoking",
                                  $$v
                                )
                              },
                              expression: "form.hypertension.data.smoking",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      true
                        ? _c(
                            "InputGroup",
                            {
                              attrs: {
                                label:
                                  _vm.$t("_hypertension_treatments") + " :",
                                required: "",
                              },
                            },
                            [
                              _c("Checkbox", {
                                attrs: {
                                  value: 4,
                                  name: "hypertensionTreatments[]",
                                  id: "hypertensionTreatment1",
                                  label: _vm.$t("_aprovel"),
                                },
                                model: {
                                  value: _vm.form.hypertension.treatments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension,
                                      "treatments",
                                      $$v
                                    )
                                  },
                                  expression: "form.hypertension.treatments",
                                },
                              }),
                              _vm._v(" "),
                              _c("Checkbox", {
                                attrs: {
                                  value: 6,
                                  name: "hypertensionTreatments[]",
                                  id: "hypertensionTreatment2",
                                  label: _vm.$t("_aprovasc"),
                                },
                                model: {
                                  value: _vm.form.hypertension.treatments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension,
                                      "treatments",
                                      $$v
                                    )
                                  },
                                  expression: "form.hypertension.treatments",
                                },
                              }),
                              _vm._v(" "),
                              _c("Checkbox", {
                                attrs: {
                                  value: 9,
                                  name: "hypertensionTreatments[]",
                                  id: "hypertensionTreatment3",
                                  label: _vm.$t("_co_aprovel"),
                                },
                                model: {
                                  value: _vm.form.hypertension.treatments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension,
                                      "treatments",
                                      $$v
                                    )
                                  },
                                  expression: "form.hypertension.treatments",
                                },
                              }),
                              _vm._v(" "),
                              _c("Checkbox", {
                                attrs: {
                                  value: 12,
                                  name: "hypertensionTreatments[]",
                                  id: "hypertensionTreatment4",
                                  label: _vm.$t("_triatec"),
                                },
                                model: {
                                  value: _vm.form.hypertension.treatments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension,
                                      "treatments",
                                      $$v
                                    )
                                  },
                                  expression: "form.hypertension.treatments",
                                },
                              }),
                              _vm._v(" "),
                              _c("Checkbox", {
                                attrs: {
                                  value: 16,
                                  name: "hypertensionTreatments[]",
                                  id: "hypertensionTreatment5",
                                  label: _vm.$t("_tritazide"),
                                },
                                model: {
                                  value: _vm.form.hypertension.treatments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension,
                                      "treatments",
                                      $$v
                                    )
                                  },
                                  expression: "form.hypertension.treatments",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      true
                        ? _c(
                            "InputGroup",
                            {
                              attrs: {
                                label:
                                  _vm.$t(
                                    "_antihypertensive_less_than_6_months"
                                  ) + " :",
                                required: "",
                              },
                            },
                            [
                              _c("Radio", {
                                attrs: {
                                  value: "1",
                                  name: "antihypertensveLessThan6Months",
                                  id: "antihypertensiveTrue",
                                  label: _vm.$t("_159"),
                                },
                                model: {
                                  value:
                                    _vm.form.hypertension.data
                                      .antihypertensive_less_than_6_months,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension.data,
                                      "antihypertensive_less_than_6_months",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.hypertension.data.antihypertensive_less_than_6_months",
                                },
                              }),
                              _vm._v(" "),
                              _c("Radio", {
                                attrs: {
                                  value: "0",
                                  name: "antihypertensveLessThan6Months",
                                  id: "antihypertensiveFalse",
                                  label: _vm.$t("_160"),
                                },
                                model: {
                                  value:
                                    _vm.form.hypertension.data
                                      .antihypertensive_less_than_6_months,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.hypertension.data,
                                      "antihypertensive_less_than_6_months",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.hypertension.data.antihypertensive_less_than_6_months",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "InputGroup",
                        { attrs: { label: _vm.$t("_161"), required: "" } },
                        [
                          _c("Checkbox", {
                            attrs: {
                              value: 3,
                              name: "hypertensionPrograms[]",
                              id: "hypertensionProgram1",
                              tooltip: _vm.$t("tooltip_hta_psp_phone_email"),
                              label: _vm.$t("_222"),
                            },
                            model: {
                              value: _vm.form.hypertension.programs,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.hypertension, "programs", $$v)
                              },
                              expression: "form.hypertension.programs",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("legend", [
                        _c("h2", { staticClass: "mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("_98"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 asteriskRequired" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("_multi_solutions_hta")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.getSolutionByGroup("has_hta", 1).length > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 col-lg-4" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "label-multisolutions solution-education",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("_99")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "InputGroup",
                                  { attrs: { required: "" } },
                                  _vm._l(
                                    _vm.getSolutionByGroup("has_hta", 1),
                                    function (solution) {
                                      return _c("Checkbox", {
                                        key: `educationSolution${solution.id}`,
                                        attrs: {
                                          value: solution.id,
                                          name: "educationSolutions[]",
                                          tooltip: _vm.helperTooltip(
                                            solution.id
                                          ),
                                          id: `monitoringSolution${solution.id}`,
                                          label: solution.name,
                                          disabled: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                          clear: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                        },
                                        model: {
                                          value: _vm.form.solutions,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "solutions", $$v)
                                          },
                                          expression: "form.solutions",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getSolutionByGroup("has_hta", 2).length > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 col-lg-4" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "label-multisolutions solution-monitoring",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("_100")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "InputGroup",
                                  _vm._l(
                                    _vm.getSolutionByGroup("has_hta", 2),
                                    function (solution) {
                                      return _c("Checkbox", {
                                        key: `monitoringSolution${solution.id}`,
                                        attrs: {
                                          value: solution.id,
                                          name: "monitoringSolutions[]",
                                          tooltip: _vm.helperTooltip(
                                            solution.id
                                          ),
                                          id: `monitoringSolution${solution.id}`,
                                          label: solution.name,
                                          disabled: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                          clear: _vm.isDisabledSolution(
                                            solution.id
                                          ),
                                        },
                                        model: {
                                          value: _vm.form.solutions,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "solutions", $$v)
                                          },
                                          expression: "form.solutions",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }