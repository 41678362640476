<template>
  <div class="page-section">
    <div v-if="formOpen != true" class="change-mode" id="modifIdentityInfo">
      <div v-on:click="openForm()">
        <i class="fas fa-pencil-alt"></i>
        <span class="change-tooltip">{{ $t('_18') }}</span>
      </div>
    </div>

    <h2>{{ $t('_84') }}</h2>
    <div class="row container-top">
      <table class="table table--user">
        <thead>
        <tr>
          <th scope="col" class="title_array_head">{{ $t('_85') }}</th>
          <th scope="col" class="title_array_head">{{ $t('_86') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="programs in programmes">
          <td>{{ programs.name }}</td>
          <td>{{ $luxon.fromSQL(date_created).setLocale("fr").toLocaleString($luxon.DATE_SHORT) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <form enctype="multipart/form-data"
          class="bg-white rounded-lg"
          method="post"
          action="/ws/update_patient.php"
          @submit.prevent="submit"
    >
      <div class="row mt-6">
        <div class="col-md-6">
          <h3 class="title_array_head mb-O">{{ $t('_87') }}</h3>
          <span>{{ user.solution }}</span>
        </div>
        <div class="col-md-6">
          <h3 class="title_array_head">{{ $t('_88') }}</h3>
          <fieldset :disabled="formOpen === false">
            <Select
              v-model="form.thematic"
              :items="thematics.items"
              :formatItems="item => item.name"
              :count="form.thematics.length"
              :disabled="formOpen === false"
              :readonly="formOpen === false"
              :checked="form.thematics"
              initialValue="Liste des thématiques"
              @loading="towns.loading = true"
              @thematics="addFormThematics"
              :loadingState="thematics.loading"
              :debounce="500"
              :bindValueOnSelect="item => item.id"
              :showValueOnSelect="item => item.name"
              name="thematic"
            />
          </fieldset>
        </div>
      </div>
      <fieldset v-if="formOpen == true" :disabled="formOpen === false">
        <Submit
          class="text-center"
          :label="$t('_195')"
        />
      </fieldset>
    </form>
  </div>
</template>

<script>
import Select from "@components/inputs/Select.vue";
import Submit from '@components/inputs/Submit.vue';
import {toFormData} from "@helpers";

export default {
  components: {
    Select,
    Submit
  },

  name: "ProgrammePatientJourney",

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },

    data_thematics: {
      type: Array,
      required: true,
      loading: false,
    },
    editModeActivated: {
      type: Function,
    },
  },

  data() {
    return {
      loading: true,
      formOpen: false,
      journey: [],
      programmes: [],
      date_created: null,
      thematics: {
        items: this.data_thematics,
        loading: false,
      },

      form: {
        form: 'form_programme_patient_journey',
        patient_id: this.user.patient_id,
        thematics: this.user.thematics,
      },
      data: []
    }
  },

  computed: {
    prefix() {
      return this.context === 'hcp' ? '/professionnel' : '/cro';
    },
  },

  mounted() {
    this.programmes = this.user.programs;
    this.date_created = this.user.date_created;
  },

  methods: {
    handleSelect(item) {
      this.$emit('select', item);
      this.selectedValue = this.showValueOnSelect(item);
      this.isOpen = false;
    },

    openForm: function () {
      this.formOpen = true
      this.editModeActivated()
    },

    addFormThematics: function (value) {
      let data = JSON.parse(JSON.stringify(value))
      this.form.thematics = data;
    },

    submit(event) {
      this.loading = true;
      this.$axios.post(event.target.action, toFormData(this.form))
        .then(response => {
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        })
        .catch(e => {
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        });
    },
  }
}
</script>

<style scoped>
.title_fiche_patient {
  color: rgb(128, 128, 128);
}

.container-top {
  margin-bottom: 30px;
}

.title_array_head {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  color: rgba(77, 77, 77, 0.87) !important;
  font-weight: normal;
  font-size: 1.4rem;
}


</style>
