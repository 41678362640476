<template>
  <div class="input-autocomplete">
    <div v-if="loadingState" class="signal">
      <div
        :style="{ color: '#cfbb97' }"
        class="spinner-border spinner-border-sm"
        role="status"
      >
        <span class="sr-only">{{ $t('_93') }}</span>
      </div>
    </div>

    <button
      class="thematic_button"
      @click="handleClear"
      :readonly="readonly"
      :disabled="disabled"
      type="button"
    >
      {{ initialValue }} <span class="thematic_count">{{ ' (' + (count) + ')' }}</span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           x="0px" y="0px"
           viewBox="0 0 407.437 407.437" style="enable-background:new 0 0 407.437 407.437;" xml:space="preserve">
        <g>
          <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093 		"/>
        </g>
      </svg>
    </button>

    <ul
      id="ModalSelect"
      v-show="isOpen"
      class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
      tabindex="-1"
    >
      <li v-for="(item, index) in items" :key="item.id"
      >
        <div class="ui-menu-item-wrapper">
          <Checkbox
            v-model="form.thematics"
            :value="item.id"
            name="thematics[]"
            :id="item.id"
            :label="item.name"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Input from '@components/inputs/Input.vue';
import Checkbox from '@components/inputs/Checkbox.vue';

export default {
  components: {Input, Checkbox},

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    name: {
      type: String,
      required: true,
    },

    count: {
      type: Number,
      required: false,
    },

    id: {
      type: String,
      required: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: {},
    },

    checked: {
      type: Array,
      default: {},
    },

    initialValue: {
      type: [Function, String, Array, Object],
      default: () => {
      },
    },

    bindValueOnSelect: {
      type: Function,
      default: item => item,
    },

    loadingState: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      isOpen: false,
      form: {
        thematics: [],
      }
    }
  },

  mounted() {
    if(this.checked.length >= 1) {
      this.form.thematics = this.checked
    }

    document.addEventListener('click', this.close);
  },

  destroyed() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    handleClear() {
      if(!this.disabled) {
        if (this.isOpen == false) {
          this.isOpen = true
        } else {
          this.isOpen = false
        }
      }
    },

    close(event) {
      if (!this.isOpen || event.defaultPrevented) {
        return;
      }

      if (
        (event.type === 'click' && !this.$el.contains(event.target)) ||
        (event.type === 'keydown' && event.key === 'Escape')
      ) {
        this.isOpen = false;
        event.preventDefault();
      }
    },
  },

  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$emit('thematics', value.thematics);
      }
    }
  }
}
</script>

<style scoped>
.ui-autocomplete {
  max-height: 250px;
}

.input-autocomplete {
  position: relative;
}

.input-autocomplete .signal {
  z-index: 1;
  position: absolute;
  top: 44px;
  left: 3px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-menu {
  top: 100%;
  width: 100%;
}

ul li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid gray;
}

.thematic_button {
  text-decoration: none;
  border: 0;
  border-bottom: 1px solid rgba(77,77,77,0.87);
  border-radius: 0;
  padding-left: 0;
  box-sizing: border-box;
  color: rgba(77,77,77,0.87);

}

.thematic_button svg {
  width: 20px;
  margin-top: 6px;
  fill: rgba(77,77,77,0.87);
}

.thematic_button svg {
  fill: rgba(77,77,77,0.87);
}

.thematic_button:hover {
  color: rgba(217,217,217,1);
}

.thematic_button:hover svg{
  fill: rgba(217,217,217,1);
}
.thematic_button:hover .thematic_count {
  color: rgba(217,217,217,1);
}

button:disabled:hover,
button[disabled]{
  color: rgba(217,217,217,1);
}

button:disabled:hover,
button[disabled] .thematic_count{
  color: rgba(217,217,217,1);
}

button:disabled:hover,
button[disabled] svg{
  fill: rgba(217,217,217,1);
}

.thematic_count {
  color: #525CA3;
}

</style>
