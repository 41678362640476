<template>
  <div class="page-section">
    <p>{{ $t('_parrainer') }}</p>
    <form
      enctype="multipart/form-data"
      class="bg-white p-4 rounded-lg"
      method="post"
      action="/professionnel/ws/parrainage_confrere.php"
      @submit.prevent="event => !loading && submit(event)"
  >
    <fieldset :disabled="loading">
      <Input
          v-model="form.email"
          :label="$t('_118')"
          name="email"
          :custom-margin='true'
          required
      />

      <InputGroup
        :label="$t('_parrainer_programme')"
        required
      >
        <Radio
            v-model="form.program"
            value="hta"
            name="program"
            id="hta"
            :label="$t('_222')"
        />
        <Radio
            v-model="form.program"
            value="diab"
            name="program"
            id="diab"
            :label="$t('_162')"
        />
        <Radio
            v-model="form.program"
            value="diab-hta"
            name="program"
            id="diab-hta"
            :label="$t('_diab_hta')"
        />
      </InputGroup>
      <br>
      <p>{{ $t('_parrainer_mail') }}</p>
      <br>
      </fieldset>

      <fieldset :disabled="loading">
        <Submit
            class="text-center"
            :label="$t('_parrainer_valider')"
        />
      </fieldset>
    </form>
  </div>
</template>

<script>
import Input from '@components/inputs/Input.vue';
import Radio from '@components/inputs/Radio.vue';
import Submit from '@components/inputs/Submit.vue';
import { toFormData } from '@helpers';

export default {
  components: {
    Input,
    Radio,
    Submit,
  },
  name: "ParrainageHcp",

  data() {
    return {
      loading: false,
      form: {
        email: '',
        program: '',  
      },
      formSuccess: false,
      errors: null,
    }
  },

  methods: {
    submit(event) {
    this.formSuccess = false;
    this.errors = null;
    this.loading = true;

    this.$axios.post('/professionnel/ws/parrainage_confrere.php', toFormData(this.form))
        .then(response => {
          this.formSuccess = true;
          this.loading = false;
          showInfo('Information', this.$t('_mail_sent'), "#dialogBox .dialog-body")
          this.form = { email: '', program: '' };   
        })
        .catch(e => {
          this.loading = false;
          const errors = e.response.data;
            showAlert('Avertissement', e.response.data, "#dialogBox .dialog-body")
          this.errors = errors;
        });
      },
    }
  }
</script>

<style scoped>

</style>
