<template>
  <div class="page-section">
    <h2>{{ $t('_89') }}</h2>
    <div>
      <p class="font-weight-bold mt-4 mb-1">{{ $t('_90') }}</p>
      <p class="nbSmsReceived">{{ datas.length }}</p>
    </div>
    <div class="row mb-4">
      <div class="col">
        <table id="teleeducation_history" class="table table--user table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t('_91') }}</th>
              <th scope="col">{{ $t('_92') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in datas">
              <td>{{ data.name }}</td>
              <td>{{ $luxon.fromISO(data.date).setLocale("fr").toLocaleString($luxon.DATE_SHORT) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeleEducationHistory",


  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      loading: false,
      formOpen: false,
      datas: [],
    }
  },

  mounted() {
    if (this.user.events.length > 0) {
      let list = [];

      $.each(this.user.events, function(key, value) {
        if(value.type == 2) {
          list.push(value);
        }
      });

      this.datas = list;
    }
  }
}
</script>

<style scoped>

</style>
