  <template>
  <div>
    <h1>{{ $t('questionnaire_title') }}</h1>
    <div class="page-section h-100">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h2>{{ $t('questionnaire_sub_title') }}</h2>
          </div>
          <div>
            <p>{{ $t('questionnaire_para_1') }}</p>
            <p>{{ $t('questionnaire_para_2') }}</p>
          </div>
          <div class="mt-4">
            <h2>{{ $t('questionnaire_info_1') }}</h2>
            <p>{{ $t('questionnaire_info_2') }}</p>
            <p>{{ $t('questionnaire_info_3') }}</p>
            <p>{{ $t('questionnaire_info_4') }}</p>
          </div>
          <form
            enctype="multipart/form-data"
            class="bg-white rounded-lg"
            method="post"
            :action="this.url"
            @submit.prevent="submit"
          >
            <ol>
              <div v-if="forms.length > 0" v-for="(question, index) in forms" :key="question.id">
                <li v-if="question.format === 'introduction'" class="container_li">
                  <label class="label_range">{{ question['question_' + lang]  }}</label>
                </li>
                <div v-else-if="question.format == 'range-intro'">
                  <li class="container_li">
                    {{ question['question_' + lang] }}
                  </li>
                  <Range v-if="question.format === 'range-intro'" name="custom_range_input" :minValue="question.possible_answers.min" :maxValue="question.possible_answers.max" class="custom_container_range input_range" :id="'myRange_' + question.id"  v-model="question.answer" :val="(question.answer) ? question.answer : null"/>
                  <textarea v-if="question.format === 'free_text'" v-model="question.answer" cols="60" rows="10"></textarea>
                </div>
                <div v-else-if="question.format == 'text'" class="container_li">
                  <p>
                    {{ question['question_' + lang] }}
                  </p>
                </div>
                <div v-else class="container_li" :class="{'sub-question': question.previous_question_format == 'text'}">
                  <label class="label_range">{{ question['question_' + lang] }}</label>
                  <Range v-if="question.format === 'range'" name="custom_range_input" :minValue="question.possible_answers.min" :maxValue="question.possible_answers.max" class="custom_container_range input_range" :id="'myRange_' + question.id"  v-model="question.answer" :val="(question.answer) ? question.answer : null"/>
                  <textarea v-if="question.format === 'free_text'" v-model="question.answer" cols="60" rows="10"></textarea>
                </div>
              </div>
            </ol>
            <fieldset>
              <Submit
                class="text-center"
                :label="$t('_195')"
              />
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Range from "@components/inputs/Range.vue";
  import Submit from "@components/inputs/Submit.vue";

  export default {
    components: {
      Range,
      Submit
    },

    props: {
      questions: {
        type: String,
        required: true,
      }
    },

    data() {
      return {
        url: window.location.origin + window.location.pathname,
        uuid: sessionStorage.getItem('uuid'),
        patient_id: sessionStorage.getItem('patient_id'),
        lang: sessionStorage.getItem('lang'),
        user: null,
        datas: {
          answers: {}
        },
        forms: {
          answers: 5
        },
      }
    },

    mounted() {
      this.parseQuestion();
    },

    methods: {
      parseQuestion: function () {
        this.forms = JSON.parse(this.questions)
      },

      submit(event) {
        let data = new FormData();

        this.forms.map(function(question) {
          if (typeof(question.answer) != 'undefined') {
            data.append('answers[' + question.id + ']', question.answer);
          }
        });

        this.$axios.post(event.target.action, data)
          .then(response => {
            this.loading = false;
            if (true === response.data.success) {
              window.location.href = window.location.origin + response.data.redirect;
            }
          })
          .catch(e => {
            const errors = e.response.data;
            this.errors = errors;
          }
        );
      },

    }
  }
</script>

<style scoped>
h1:last-of-type, h2:last-of-type, h3:last-of-type, h4:last-of-type, h5:last-of-type, h6:last-of-type {
  margin-bottom: 2rem;
}
.container_li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 10px;
}

.label_range {
  margin-bottom: 20px
}

.sub-question {
  margin-left: 20px;
}

.custom-range-wrapper {
  width: 50%;
}


@media (max-width: 1200px) {
  textarea {
    width: 100%;
  }

  .custom-range-wrapper {
    width: 100%;
  }
}
</style>
